import React from "react";
import moment from "moment";

function RCRecieved({ SaveRTO, values, handleInputChange, setActiveTab }) {
  return (
    <>
      <fieldset className="mt-4">
      <div className="row">
          <div className="col">
            <div className="form-group text-center">
              <div className="">
                <b>
                  <h3 className="text-start">
                    Due Amount:
                    {values.work_fee - values.totalApplicantpaidAmount || 0}
                  </h3>
                </b>
              </div>
            </div>
          </div>
          <div className="col text-end">
            <button
              className="btn btn-primary p-2  "
              onClick={() => SaveRTO("rtoPayment")}
            >
              Pay Due Now
            </button>
          </div>
        </div>
        <legend>
          <span> Rc Recieved Details</span>
        </legend>
     

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>RC Card Status</label>
              <select
                className="form-control "
                onChange={(e) => handleInputChange(e)}
                name="rc_card_status"
                value={values.rc_card_status}
              >
                <option value="">--select--</option>
                <option value="Pending">pending</option>
                <option value="Recieved">Recieved</option>
              </select>
            </div>
          </div>

          {values.rc_card_status === "Recieved" && (
            <>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="">RC Received Date</label>
                  <input
                    className="form-control"
                    placeholder="Enter RC Received Date"
                    type="date"
                    onChange={(e) => handleInputChange(e)}
                    name="rc_received_date"
                    // value={values.rc_received_date}
                    value={moment(values.rc_received_date).format("YYYY-MM-DD")}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="">RC Card Receiver Customer Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter RC Card Receiver Customer Name"
                    type="text"
                    onChange={(e) => handleInputChange(e)}
                    name="rc_card_receiver_customer_name"
                    value={values.rc_card_receiver_customer_name}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="">
                    RC Card Receiver Customer Mobile No.:
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter RC Card Receiver Customer Mobile No."
                    type="text"
                    onChange={(e) => handleInputChange(e)}
                    name="rc_card_receiver_customer_mobile_no"
                    value={values.rc_card_receiver_customer_mobile_no}
                  />
                </div>
              </div>

              <div className="col-12 d-flex  align-items-center">
                <button
                  className="btn btn-primary text-white d-inine"
                  onClick={() => SaveRTO("final")}
                  // onClick={}
                >
                  Save RC Statuc
                </button>
              </div>
            </>
          )}
        </div>
      </fieldset>
    </>
  );
}

export default RCRecieved;
