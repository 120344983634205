import axios from "axios";
import {
  GetJSONHederWithToken,
  GetMultipartHederWithToken,
} from "../../Utilities/CommonMethods";

const BASE_URL = process.env.REACT_APP_BASE_URL;
export const getDistrict = async () => {
  try {
    let header = GetJSONHederWithToken();
    const data = await axios.get(
      `${BASE_URL}admin/getAllDistrictTehsil`,
      header
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const getDocName = async () => {
  let header = GetMultipartHederWithToken();
  try {
    const data = await axios.post(`${BASE_URL}admin/getAllDocs`, {}, header);
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getLoanDocs = async (loanId) => {
  let header = GetJSONHederWithToken();
  try {
    const data = await axios.post(
      `${BASE_URL}admin/getAllLoanDocs`,
      { loanId: loanId },
      header
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteDoc = async (id) => {
    let header = GetJSONHederWithToken();
    try {
        if (!window.confirm("Do you really want to delete this item?")) {
            return;
          }
      const data = await axios.delete(
        `${BASE_URL}admin/deleteLoanDoc/${id}`,
         header
      );
      return data;
    } catch (err) {
      console.log(err);
    }
  };
