import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { Link } from "react-browser-router";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
const initialState = {
  salary_year:"",
  salary_month:"",
  total_working_days:"",
  paid_holidays:"",
  paid_leaves:"",
  unpaid_leaves:0,
  payable_days:"",
  leave_amount:0,
  payable_salary:"",
  deduction_amount:0,
  commission: "",
  salary: 0,
  bonus: 0,
  net_payable_salary: "",
  employeeId:"",

  payment_mode: "Cash",
};
function GenerateSalary() {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const currentDate = new Date();
const currentMonthName = monthNames[currentDate.getMonth()];
const currentYear = new Date().getFullYear();
const startYear = 2018;
  const navigate = useNavigate();
 const [ loading , setLoading]=useState(false);
  const [years, setYears] = useState([]);

  const { state } = useLocation();
  console.log("state", state);
  const [values, setValues] = useState(initialState);
  const [employees , setEmployees]=useState([]);
  const [selectedEmployee , setSelectedEmployee]=useState({});
  const [employeeId, setEmployeeId] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
    const handleOnSearch = (string, results) => {
    console.log(string, results)
  }
   const handleOnSelect = (item) => {
    setSelectedEmployee(item);
    console.log(item);
    values.salary=item.salary;
    values.bonus=item.bonus;
    values.deduction_amount=item.deduction;
    values.employeeId=item._id
  }
    const formatResult = (item) => {
    return (
        <span style={{ display: 'block', textAlign: 'left' }}>
          <p className="m-0">name: {item.name}</p>
          <p className="m-0">Mobile No.: {item.mobile_no}</p>
          </span>
    )
  }
  const getData = () => {
    const token= localStorage.getItem('token');
  
    axios
      .post(`${BASE_URL}admin/getAllEmployees`, {}, {
        headers: {
          'x-access-token':`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setEmployees(response.data.data);
          console.log("employees",response.data.data);
        }else{
          if(response.data.error_code==461){
            navigate("/login")
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  useEffect(() => {
    const yearsArray = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };


  // add update product
  const SaveEmployeeSalary = (step) => {
    const token = localStorage.getItem("token");
  let obj={}
    let api = "addEmployeeSalary";
    if (employeeId) {
      api = "updateEmployeeSalary";
    }
      obj.salary_year=values.salary_year.toString();
      obj.salary_month=values.salary_month;
      obj.total_working_days=values.total_working_days;
      obj.paid_holidays=values.paid_holidays;
      obj.paid_leaves=values.paid_leaves;
      obj.unpaid_leaves=values.unpaid_leaves;
      obj.payable_days=values.payable_days;
      obj.leave_amount=values.leave_amount;
      obj.payable_salary=values.payable_salary;
      obj.deduction_amount=values.deduction_amount;
      obj.salary=values.salary;
      obj.bonus=values.bonus;
      obj.net_payable_salary=values.net_payable_salary;
      obj.employeeId=values.employeeId
      obj.payment_mode=values.payment_mode
      setLoading(true);
    axios
      .post(`${BASE_URL}admin/${api}`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setValues(initialState);
          toast.success(response.data.message);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  // keyword end
  useEffect(() => {
    getData();
      const leave_amount = parseInt(selectedEmployee?.salary / values?.total_working_days) * parseInt(values?.unpaid_leaves);
      const payableSalary=selectedEmployee.salary-leave_amount
      console.log("leave amount",parseInt(selectedEmployee?.salary / values?.total_working_days));
      setValues(prevValues => ({
        ...prevValues,
        leave_amount,
        payable_salary:payableSalary
      }));
    
  }, [values.unpaid_leaves,employees.salary]);

  useEffect(()=>{
    if(values.leave_amount>0&&values.payable_salary>0){
      const NetPayableSalary=parseInt(values.payable_salary)+parseInt(values.bonus)-parseInt(values.deduction_amount);
      setValues(prevValues => ({
        ...prevValues,
        net_payable_salary:NetPayableSalary
      }));
    }
 
  },[values.leave_amount,values.payable_salary,values.bonus , values.deduction_amount])
  useEffect(()=>{
    if (values.unpaid_leaves) {
      setValues(prevValues => ({
        ...prevValues,
        payable_days: parseInt(values.total_working_days) - parseInt(values.unpaid_leaves)
      }));
    }
  },[values.unpaid_leaves,values.total_working_days])

  useEffect(()=>{
    setValues(prevValues => ({
      ...prevValues,
      salary_month:currentMonthName,
      salary_year:currentYear.toString(),
    }));
  },[])

  return (
    <Layout>
        <div className="content-i">
      <div className="content-box">
        <div className="element-wrapper pb-0">
          <div className="element-box">
          <div >
          <ReactSearchAutocomplete
            items={employees}
            onSearch={handleOnSearch}
            // onHover={handleOnHover}
            onSelect={handleOnSelect}
            placeholder="Search Employee By Name"
            // onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
          />
        </div>
            <h5 className="form-header">Employee Listing</h5>
            <div className="table-responsive">
              {Object.keys(selectedEmployee).length >0 &&
                <table className="table table-lightborder">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Mobile Number</th>
                    <th>Joining Date</th>
                    <th>Salary</th>
                    
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                      <tr >
                      <td>{selectedEmployee.name}</td>
                      <td>{selectedEmployee.mobile_no}</td>
                      <td>{moment(selectedEmployee.joining_date).format('YYYY-MM-DD')}</td>
                      <td>{selectedEmployee.salary}</td>
                      <td>{selectedEmployee.status}</td>
                    </tr>
                </tbody>
              </table>
              }
            
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <h6 className="element-header"> Generate Salary</h6>
                <div className="element-box">
                  <div>
                    <h5 className="form-header">Generate Salary</h5>
                    <div className="row">
             
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Year:</label>
                          <select className="form-control"
                           onChange={(e) => handleInputChange(e)}
                           name="salary_year"
                           value={values.salary_year}
                          >
                            <option value="">--Select a year--</option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Months</label>
                          <select className="form-control"
                            onChange={(e) => handleInputChange(e)}
                            name="salary_month"
                            value={values.salary_month}
                          >
                            <option value="">--Select a month--</option>
                              <option  value="January">January</option>
                              <option  value="Febuary">Febuary</option>
                              <option  value="March">March</option>
                              <option  value="April">April</option>
                              <option  value="May">May</option>
                              <option  value="June">June</option>
                              <option  value="July">July</option>
                              <option  value="August">August</option>
                              <option  value="September">September</option>
                              <option  value="October">October</option>
                              <option  value="November">November</option>
                              <option  value="December">December</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Total Working Days</label>
                          <input
                            className="form-control"
                            placeholder="Enter Total Working Days"
                            name="total_working_days"
                            onChange={(e) => handleInputChange(e)}
                            value={values.total_working_days}
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Paid Holidays</label>
                          <input
                            className="form-control"
                            placeholder="Enter Paid Holidays"
                            name="paid_holidays"
                            onChange={(e) => handleInputChange(e)}
                            value={values.paid_holidays}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Paid Leaves</label>
                          <input
                            className="form-control"
                            placeholder="Enter Paid Leaves"
                            name="paid_leaves"
                            onChange={(e) => handleInputChange(e)}
                            value={values.paid_leaves}
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Unpaid Leaves</label>
                          <input
                            className="form-control"
                            placeholder="Enter Unpaid Leaves"
                            name="unpaid_leaves"
                            onChange={(e) => handleInputChange(e)}
                            value={values.unpaid_leaves}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Payable Days</label>
                          <input
                            className="form-control"
                            placeholder="Enter Payable Days"
                            name="payable_days"
                            onChange={(e) => handleInputChange(e)}
                            value={values.payable_days}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Salary</label>
                          <input
                            className="form-control"
                            placeholder="Enter Salary"
                            name="salary"
                            onChange={(e) => handleInputChange(e)}
                            // defaultValue={employees.salary}
                            value={values.salary}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Leave Amount</label>
                          <input
                            className="form-control"
                            placeholder="Enter Leave Amount"
                            name="leave_amount"
                            onChange={(e) => handleInputChange(e)}
                            value={values.leave_amount}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Payable Salary</label>
                          <input
                            className="form-control"
                            placeholder="Enter Leave Amount"
                            name="payable_salary"
                            onChange={(e) => handleInputChange(e)}
                            value={values.payable_salary}
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Bonus Amount</label>
                          <input
                            className="form-control"
                            placeholder="Enter Bonus"
                            name="bonus"
                            onChange={(e) => handleInputChange(e)}
                            value={values.bonus}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Deduction Amount</label>
                          <input
                            className="form-control"
                            placeholder="Enter Leave Amount"
                            name="deduction_amount"
                            onChange={(e) => handleInputChange(e)}
                            value={values.deduction_amount}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Net Payable Salary</label>
                          <input
                            className="form-control"
                            placeholder="Enter Net Payable Salary"
                            name="net_payable_salary"
                            onChange={(e) => handleInputChange(e)}
                            value={values.net_payable_salary}
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Payment Mode</label>
                          <select
                            className="form-control"
                            name="payment_mode"
                            onChange={(e) => handleInputChange(e)}
                            value={values.payment_mode}
                          >
                            <option value="Cash">Cash Payment</option>
                            <option value="UPI">UPI Payment</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="form-buttons-w">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => SaveEmployeeSalary()}
                      >
                        {
                          loading? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            "Submit"
                          )
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default GenerateSalary;
