import React, { useEffect, useRef, useState } from "react";
import Layout from "../../../components/Layout";
import { Link } from "react-browser-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import moment from "moment";


const initialState = {
  name: "",
  email: "",
  mobile_no: "1234567890",
  alternative_mobile_no: "",
  dob: "",
  pincode: "",
  state: "",
  district: "",
  tehsil: "",
  tehsil1: "",
  address: "",

  

  account_holder_name: "",
  bank_name: "",
  branch_name: "",
  account_number: 0,
  ifsc_code: "",
 
  status: "Active",
};
function AddRTOAgent() {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const { state } = useLocation();
  // console.log("state", state);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [districtTehsils, setDistrictTehsils] = useState([]);
  const [employees, setEmployees] = useState("");
  const [activeTab, setActiveTab] = useState("firstStep");
  const [states, setStates] = useState([]);
  const [agentId, setAgentId] = useState("");

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const handleTabClick = (tabName) => {
    // console.log("okkkk", tabName);

    if (tabName === "secondStep") {
      if (!values.name) {
        toast.error("Name is required");
        return false;
      }

      if (!values.mobile_no) {
        toast.error("Mobile Number is required");
        return false;
      }
      else {
        return true;
      }
    } else if (tabName === "thirdStep") {
      if (!values.vehicle_type) {
        toast.error("Vehicle Type is required");
        return;
      }

      if (!values.vehicle_no) {
        toast.error("Vehicle No is required");
        return false;
      }
      if (!values.model) {
        toast.error("Model  is required");
        return false;
      }
      if (!values.distt_rto) {
        toast.error("Distt RTO is required");
        return false;
      } else {
        return true;
      }
    } else if (tabName === "fourthStep") {
      return true;
    } else if (tabName === "final") {
     
        return true;
      
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
   
      setValues({
        ...values,
        [name]: value,
      });
    
  };


  // add update product
  const Save = (step) => {
    console.log(values);
    let obj={}
    if (!handleTabClick(step)) {
      // console.log("in");
      return;
    }
    const token = localStorage.getItem("token");
    // const fd = new FormData();
    let api = "addRTOAgent";
    if (agentId) {
      api = "updateRTOAgent";
    }

    obj.name= values.name;
    obj.email=values.email;
    obj.mobile_no= values.mobile_no;
    obj.alternative_mobile_no= values.alternative_mobile_no;
    if(values.dob!=null){
        obj.dob=values.dob;
    }
    obj.pincode= values.pincode;
    obj.state= values.state;
    obj.district= values.district;
    obj.tehsil= values.tehsil;

    obj.tehsil1= values.tehsil1;
    obj.address= values.address;
    obj.account_holder_name= values.account_holder_name;
    obj.bank_name= values.bank_name;
    obj.branch_name= values.branch_name;
    obj.account_number= values.account_number;
    obj.ifsc_code= values.ifsc_code;
    obj.status= values.status;
    if (agentId) {
      obj.id= agentId;
    }
    axios
      .post(`${BASE_URL}admin/${api}`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          if (step === "final") {
            navigate("/rto-agent-list");
          }
          // handleTabClick(step);
          setAgentId(response.data.data._id);
          setValues(initialState);
          toast.success(response.data.message);
          setValues({ ...response.data.data });
          if (step == "secondStep") {
            AddTehsils();
          }
          setActiveTab(step);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // state district tehsil
  const getStates = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getALLState`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setStates(response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const getDistrictTehsil = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllDistrictTehsil`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setDistrictTehsils(response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const AddTehsils = () => {
    const token = localStorage.getItem("token");

    setLoading(true);
    axios
      .post(
        `${BASE_URL}admin/updateTehsils`,
        { tehsil: values.tehsil1, district: values.district },
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
        
      });
  };
  // end state district tehsil

  // keyword end
  useEffect(() => {
    if (state) {
      setValues({ ...state.item });
      setAgentId(state.item._id);
    }
    // getData();
    getStates();
    getDistrictTehsil();
  }, []);



  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div>
                <div className="steps-w w-100">
                  <div className="step-triggers">
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${
                        activeTab == "firstStep" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("firstStep")}
                    >
                      Basic Information
                    </a>
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${
                        activeTab == "secondStep" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("secondStep")}
                    >
                      Bank Details
                    </a>
                    {/* <a
                      href="javascript:void(0)"
                      className={`step-trigger ${
                        activeTab == "fifthStep" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("fifthStep")}
                    >
                      Deduction Expence Amount
                    </a> */}
                  </div>
                  <div className="step-contents">
                    <div
                      className={`step-content  ${
                        activeTab == "firstStep" ? "active" : ""
                      }`}
                      id="stepContent1"
                      style={{
                        display: activeTab == "firstStep" ? "block " : "none",
                      }}
                    >
                      <fieldset>
                        <legend>
                          <span>Basic Information</span>
                        </legend>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Full Name</label>
                              <input
                                className="form-control"
                                placeholder="Enter Name"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                name="name"
                                value={values.name}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Email:</label>
                              <input
                                className="form-control"
                                placeholder="Enter Email"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                name="email"
                                value={values.email}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Mobile Number:</label>
                              <input
                                className="form-control"
                                placeholder="Enter Mobile No."
                                type="Number"
                                onChange={(e) => handleInputChange(e)}
                                name="mobile_no"
                                value={values.mobile_no}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Alternative Mobile Number:</label>
                              <input
                                className="form-control"
                                placeholder="Enter Alternative Mobile No."
                                type="Number"
                                onChange={(e) => handleInputChange(e)}
                                name="alternative_mobile_no"
                                value={values.alternative_mobile_no}
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for=""> Date of Birth</label>
                              <input
                                class="form-control"
                                placeholder=""
                                type="date"
                                onChange={(e) => handleInputChange(e)}
                                name="dob"
                                value={moment(values.dob).format("YYYY-MM-DD")}
                              />
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="">Pincode </label>
                              <input
                                class="form-control"
                                placeholder="Enter Pincode"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                name="pincode"
                                value={values.pincode}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label> state</label>
                              <select
                                className="form-control "
                                onChange={(e) => handleInputChange(e)}
                                name="state"
                                value={values.state}
                              >
                                <option value="">--select--</option>
                                {states.map((state) => (
                                  <option value={state.name} key={state.key}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          {values.state == "Madhya Pradesh" ? (
                            <>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label> District</label>
                                  {/* <button className="d-none" onClick={()=>console.log(values)}>click</button> */}
                                  <select
                                    className="form-control "
                                    onChange={(e) => handleInputChange(e)}
                                    name="district"
                                    value={values?.district}
                                  >
                                    <option value="">--select--</option>

                                    {districtTehsils.map((item) => (
                                      <option
                                        key={item.district}
                                        value={item.district}
                                      >
                                        {item.district}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              {values.district?.length > 0 &&
                                values.tehsil != "Other" && (
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <label> Tehsils</label>

                                      <select
                                        className="form-control "
                                        onChange={(e) => handleInputChange(e)}
                                        name="tehsil"
                                        value={values?.tehsil}
                                      >
                                        <option value="">--select--</option>

                                        {districtTehsils
                                          .find(
                                            (districtObj) =>
                                              districtObj.district ===
                                              values.district
                                          )
                                          ?.tehsils?.map((tehsil) => (
                                            <option key={tehsil} value={tehsil}>
                                              {tehsil}
                                            </option>
                                          ))}
                                        <option value="Other">Other</option>
                                      </select>
                                    </div>
                                  </div>
                                )}

                              {values.tehsil == "Other" && (
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label for="">Tehsils</label>
                                    <input
                                      className="form-control text-capitalize"
                                      placeholder="Enter Tehsil Name"
                                      type="text"
                                      onChange={(e) => handleInputChange(e)}
                                      name="tehsil1"
                                      value={values.tehsil1}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label for="">District</label>
                                  <input
                                    className="form-control"
                                    placeholder="Enter District Name"
                                    type="text"
                                    onChange={(e) => handleInputChange(e)}
                                    name="district"
                                    value={values.district}
                                  />
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label for="">Tehsils</label>
                                  <input
                                    className="form-control text-capitalize"
                                    placeholder="Enter Tehsil Name"
                                    type="text"
                                    onChange={(e) => handleInputChange(e)}
                                    name="tehsil"
                                    value={values.tehsil}
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Address</label>
                              <input
                                name="address"
                                rows={4}
                                placeholder="Enter Permanent Address "
                                className="form-control"
                                onChange={(e) => handleInputChange(e)}
                                value={values.address}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </fieldset>

                     
                      <div className=" text-right">
                        <button
                          className="btn btn-primary text-white"
                          onClick={() => Save("secondStep")}
                          // onClick={}
                        >
                          Save & Continue
                        </button>
                      </div>
                    </div>
                  
                  
                    <div
                      className={`step-content ${
                        activeTab == "secondStep" ? "active" : ""
                      }`}
                      id="stepContent3"
                      style={{
                        display: activeTab == "secondStep" ? "block" : "none",
                      }}
                    >
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="">Account Holder Name:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Account Holder Name"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="account_holder_name"
                              value={values.account_holder_name}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="">Bank Name:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Bank Name"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="bank_name"
                              value={values.bank_name}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="">Branch Name:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Branch Name"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="branch_name"
                              value={values.branch_name}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="">Account Number:</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Account Number"
                              onChange={(e) => handleInputChange(e)}
                              name="account_number"
                              value={values.account_number}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="">IFSC Code:</label>
                            <input
                              className="form-control"
                              placeholder="Enter IFSC Code"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="ifsc_code"
                              value={values.ifsc_code}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-buttons-w text-right">
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => setActiveTab("secondStep")}
                          >
                            Back
                          </button>
                        </span>
                        &nbsp;&nbsp;{" "}
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => Save("final")}
                          >
                            Save
                          </button>
                        </span>
                        &nbsp;&nbsp;
                      </div>
                    </div>

                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddRTOAgent;
