import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import district_tehsil from "../data/district-tehsil.json";
import indianStates from "../data/IndianStates.json";
const initialState = {
  companyName: "",
  email: "",
  mobile_no: "",
  state: "",
  district: "",
  tehsil:"",
  industry: "",
  website: "",
  description: "",
  logo: "",
  address: "",
  _id: "",
};
function Profile() {
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [values, setValues] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "logo") {
      setValues({
        ...values,
        [name]: files[0],
      });
      if (files && files.length > 0) {
        const newImageSrc = URL.createObjectURL(files[0]);
        setImage((e) => [newImageSrc]);
      }
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };
  const viewProfile=()=>{
    const token= localStorage.getItem('token')
    axios
    .get(`${BASE_URL}admin/viewCompanyProfile`,{
      headers:{
        "x-access-token":`${token}`,
        "Content-Type": "Application/json",
        version: "1.0.0",
      }
    }).then((response)=>{
      if(response.data.success){
        setValues(response.data.data);
        setImage(response.data.data.logo)
        console.log(response.data.data.logo)

        // console.log(response.data.data)
      }else{
        toast.error(response.data.message);
      }
    }).catch((error)=>{
      console.log(error.message);
    })
  }

  const saveUpdateProfile = () => {
    const token = localStorage.getItem("token");
    axios
      .post(`${BASE_URL}admin/companyProfile`, values, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          setValues({...response.data.data});
          setImage(response.data.data.logo)
          console.log(response.data.data.logo)

          console.log(values);
        } else {
          if (response.data.error_code === 461) {
            navigate("/login");
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(()=>{
    viewProfile();
  },[])
  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-sm-5">
              <div className="user-profile compact">
                <div
                  className="up-head-w"
                  style={{ backgroundImage: `url(${image})` }}
                >
                  <div className="up-social">
                    <a href="#">
                      <i className="os-icon os-icon-twitter" />
                    </a>
                    <a href="#">
                      <i className="os-icon os-icon-facebook"></i>
                    </a>
                  </div>
                  <div className="up-main-info">
                    <h2 className="up-header">{values?.companyName}</h2>
                    <h6 className="up-sub-header">
                      ..
                      {/* Product Designer at Facebook */}
                    </h6>
                  </div>
                  <svg
                    className="decor"
                    width="842px"
                    height="219px"
                    viewBox="0 0 842 219"
                    preserveAspectRatio="xMaxYMax meet"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      transform="translate(-381.000000, -362.000000)"
                      fill="#FFFFFF"
                    >
                      <path
                        className="decor-path"
                        d="M1223,362 L1223,581 L381,581 C868.912802,575.666667 1149.57947,502.666667 1223,362 Z"
                      />
                    </g>
                  </svg>
                </div>
                <div className="up-controls">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="value-pair">
                        <div className="label">Status:</div>
                        <div className="value badge badge-pill badge-success">
                          {values?.status}
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                {/* <div className="up-contents">
                  <div className="m-b">
                    <div className="row m-b">
                      <div className="col-sm-6 b-r b-b">
                        <div className="el-tablo centered padded-v">
                          <div className="value">25</div>
                          <div className="label">Products Sold</div>
                        </div>
                      </div>
                      <div className="col-sm-6 b-b">
                        <div className="el-tablo centered padded-v">
                          <div className="value">315</div>
                          <div className="label">Friends</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-sm-7">
              <div className="element-wrapper">
                <div className="element-box">
                  <div id="formValidate">
                    <div className="element-info">
                      <div className="element-info-with-icon">
                        <div className="element-info-icon">
                          <div className="os-icon os-icon-wallet-loaded"></div>
                        </div>
                        <div className="element-info-text">
                          <h5 className="element-inner-header">
                            Profile Settings
                          </h5>
                          {/* <div className="element-inner-desc">
                            Validation of the form is made possible using
                            powerful validator plugin for bootstrap.
                            <a
                              href="http://1000hz.github.io/bootstrap-validator/"
                              target="_blank"
                            >
                              Learn more about Bootstrap Validator
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor>Company Name</label>
                      <input
                        className="form-control"
                        data-error="Company name is required"
                        placeholder="Enter company name"
                        required="required"
                        name="companyName"
                        value={values?.companyName}
                        onChange={(e) => handleInputChange(e)}
                        type="text"
                      />
                      <div className="help-block form-text with-errors form-control-feedback"></div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Public Email</label>
                          <input
                            className="form-control"
                            placeholder="Email"
                            required="required"
                            type="email"
                            name="email"
                            value={values?.email}
                            onChange={(e) => handleInputChange(e)}
                          />
                          <div className="help-block form-text text-muted form-control-feedback"></div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Mobile Number</label>
                          <input
                            className="form-control"
                            placeholder="Mobile Number"
                            required="required"
                            type="number"
                            name="mobile_no"
                            value={values?.mobile_no}
                            onChange={(e) => handleInputChange(e)}
                          />
                          <div className="help-block form-text with-errors form-control-feedback"></div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-sm-6"> */}
                            <div className="form-group">
                              <label> state</label>
                              <select
                                className="form-control "
                                onChange={(e) => handleInputChange(e)}
                                name="state"
                                value={values.state}
                              >
                                <option value="">--select--</option>
                                {indianStates.map((state) => (
                                  <option value={state.name} key={state.key}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          {/* </div> */}

                          {values.state == "Madhya Pradesh" ? (
                            <>
                              {/* <div className="col-sm-6"> */}
                                <div className="form-group">
                                  <label> District</label>
                                  <select
                                    className="form-control "
                                    onChange={(e) => handleInputChange(e)}
                                    name="district"
                                    value={values.district}
                                  >
                                    <option value="">--select--</option>

                                    {district_tehsil.MadhyaPradesh.map(
                                      (districtObj, index) => (
                                        <option
                                          key={index}
                                          value={districtObj.district}
                                        >
                                          {districtObj.district}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              {/* </div> */}

                              {values.district?.length > 0 && (
                                // <div className="col-sm-6">
                                  <div className="form-group">
                                    <label> Tehsils</label>
                                    <select
                                      className="form-control "
                                      onChange={(e) => handleInputChange(e)}
                                      name="tehsil"
                                      value={values.tehsil}
                                    >
                                      <option value="">--select--</option>

                                      {district_tehsil.MadhyaPradesh.find(
                                        (districtObj) =>
                                          districtObj.district ===
                                          values.district
                                      ).tehsils.map((tehsil, index) => (
                                        <option key={index} value={tehsil}>
                                          {tehsil}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                // </div>
                              )}
                            </>
                          ) : (
                            <>
                              {/* <div className="col-sm-6"> */}
                                <div className="form-group">
                                  <label for="">District</label>
                                  <input
                                    className="form-control"
                                    placeholder="Enter District Name"
                                    type="text"
                                    onChange={(e) => handleInputChange(e)}
                                    name="district"
                                    value={values.district}
                                  />
                                </div>
                              {/* </div> */}

                              {/* <div className="col-sm-6"> */}
                                <div className="form-group">
                                  <label for="">Tehsils</label>
                                  <input
                                    className="form-control"
                                    placeholder="Enter Tehsil Name"
                                    type="text"
                                    onChange={(e) => handleInputChange(e)}
                                    name="tehsil"
                                    value={values.tehsil}
                                  />
                                </div>
                              {/* </div> */}
                            </>
                          )}
                    <div className="form-group">
                      <label htmlFor>Industory</label>
                      <input
                        className="form-control"
                        data-error="Your Company Industory"
                        placeholder="Enter Industory"
                        required="required"
                        type="text"
                        onChange={(e) => handleInputChange(e)}
                        name="industry"
                        value={values?.industry}
                      />
                      <div className="help-block form-text with-errors form-control-feedback"></div>
                    </div>
                    <div className="form-group">
                      <label htmlFor>Website</label>
                      <input
                        className="form-control"
                        data-error="Your Wensite URL"
                        placeholder="Enter Industory"
                        required="required"
                        type="text"
                        onChange={(e) => handleInputChange(e)}
                        name="website"
                        value={values?.website}
                      />
                      <div className="help-block form-text with-errors form-control-feedback"></div>
                    </div>
                    <div className="form-group">
                      <label>Company address</label>
                      <textarea
                        className="form-control"
                        rows={3}
                        defaultValue={""}
                        onChange={(e) => handleInputChange(e)}
                        name="address"
                        value={values?.address}
                      />
                    </div>
                    <div className="form-group">
                      <label>Company Description</label>
                      <textarea
                        className="form-control"
                        rows={3}
                        defaultValue={""}
                        onChange={(e) => handleInputChange(e)}
                        name="description"
                        value={values?.description}
                      />
                    </div>

                    <div className="form-group">
                      <label>Company Logo</label>
                      <input
                        className="form-control"
                        // data-error="Your Wensite URL"
                        placeholder="Logo"
                        required="required"
                        type="file"
                        onChange={(e) => handleInputChange(e)}
                        name="logo"
                      />
                    </div>
                    {/* <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" />I
                        agree to terms and conditions
                      </label>
                    </div> */}
                    <div className="form-buttons-w">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => saveUpdateProfile()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Profile;
