import axios from "axios";
import {
  GetJSONHederWithToken,
  GetMultipartHederWithToken,
} from "../../Utilities/CommonMethods";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ContactPeople = async (id) => {
  let header = GetJSONHederWithToken();
  try {
    const data = await axios.post(`${BASE_URL}admin/getContactPeopleByCompanyId`, {id:id}, header);
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const SaveContactPerson = async (obj) => {
    let header = GetJSONHederWithToken();
    try {
      const data = await axios.post(`${BASE_URL}admin/addContactPerson`, obj, header);
      return data;
    } catch (err) {
      console.log(err);
    }
  };

export const SaveLoanCompanyContactPerson=async(obj)=>{
    let header = GetJSONHederWithToken();
    try {
      const data = await axios.post(`${BASE_URL}admin/addLoanCompanyContactPerson`, obj, header);
      return data;
    } catch (err) {
      console.log(err);
    }
}
  
