import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import Layout from "../../../components/Layout";
import { Hearts } from "react-loader-spinner";
const initialState = {
    headId:"",
  name: "",
  status: "Active",
  
};
const AddSubhead = () => {
  const [values, setValues] = useState(initialState);
  const [page, setPage] = useState(1);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [allHead,setAllHead]=useState([]);
  const [allSubhead,setAllSubhead]=useState([]);

  


  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
      setValues({
        ...values,
        [name]: value,
      });
    
  };

  const saveupdatedHead = () => {
    const token= localStorage.getItem('token');
    if (!values.headId) {
      toast.error("Please Select Subhead !");
      return;
    }
    if (!values.name) {
        toast.error("Please Enter Subhead !");
        return;
      }

    let api = "addSubhead";
    if(values.id){
       api = "updateSubhead";
    }
    axios
      .post(`${BASE_URL}admin/${api}`, values, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setValues(initialState);
          toast.success(response.data.message);
          getData();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const UpdateHead = (item) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setValues({
      id: item._id,
      name: item.name,
      status: item.status,
    });
  };
  const getData = () => {
    const token= localStorage.getItem("token");
    const obj = {
      page: page,
      limit: 8,
    };
    axios
      .post(`${BASE_URL}admin/getSubhead`, obj, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",

        },
      })
      .then((response) => {
        if (response.data.success) {
          setAllSubhead(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const getHeadData = () => {
    const token= localStorage.getItem("token");
  
    axios
      .post(`${BASE_URL}admin/getAllExpenceHead`, {}, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",

        },
      })
      .then((response) => {
        if (response.data.success) {
          setAllHead(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteHead = (id) => {
    const token= localStorage.getItem('token');
    if (!window.confirm("Do you really want to delete this item?")) {
      return;
    }
    axios
      .delete(`${BASE_URL}admin/deleteSubhead/${id}`, {
        headers: {
          'x-access-token':`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.message);
          setAllSubhead([]);
          getData();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getData();
    getHeadData();
  }, []);

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <h6 className="element-header">Expences Head</h6>
                <div className="element-box">
                  <div>
                    <h5 className="form-header">Add Head</h5>
                    <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Expences Head</label>
                          <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e)}
                            name="headId"
                            value={values.headId}
                          >
                                <option value="">-select head-</option>

                          {
                            allHead?.length>0&&allHead.map((item , index)=>(
                                <option value={item._id}>{item.name}</option>
                                
                            ))
                          }
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor> Name:</label>
                          <input
                            className="form-control"
                            placeholder="Enter Name"
                            name="name"
                            onChange={(e) => handleInputChange(e)}
                            value={values.name}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Status</label>
                          <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e)}
                            name="status"
                            value={values.status}
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                    
                    </div>

                    <div className="form-buttons-w">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => saveupdatedHead()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* /////////////// category listing  start */}

      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <h5 className="form-header">Head Listing</h5>

              <div className="table-responsive">
                <table className="table table-lightborder">
                  <thead>
                    <tr>
                      <th>Head</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allSubhead?.map((item, index) => (
                      <tr key={index}>
                       <td>{item?.heads[0]?.name}</td>
                        <td>{item.name}</td>
                        <td>{item.status}</td>

                        <td>
                          <button
                            className="border-0 bg-white"
                            onClick={() => UpdateHead(item)}
                          >
                            <i className="os-icon os-icon-pencil-2" />
                          </button>

                          <button
                            className="text-danger border-0 bg-white ps-2"
                            onClick={() => deleteHead(item._id)}
                          >
                            <i className="os-icon os-icon-database-remove" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddSubhead;
