import axios from "axios";
import {
  GetJSONHederWithToken,
} from "../../Utilities/CommonMethods";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getLoanHistory = async (obj) => {
    try {
      let header = GetJSONHederWithToken();
      const data = await axios.post(
        `${BASE_URL}admin/getLoanCompaniesHistory`,obj,
        header
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  };