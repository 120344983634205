import axios from "axios";
import {
  GetJSONHederWithToken,
} from "../../Utilities/CommonMethods";

const BASE_URL = process.env.REACT_APP_BASE_URL;
export const getAllRTOAgent = async () => {
  try {
    let header = GetJSONHederWithToken();
    const data = await axios.post(
      `${BASE_URL}admin/getAllRTOAgent`,{},
      header
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};