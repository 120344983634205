export function GetJSONHederWithToken() {
    const token = localStorage.getItem("token");
    var header = {
        headers: {
            'Content-Type': 'application/json',
            "x-access-token": `${token}`,
            version: "1.0.0",
        }
    };
    return header;
}
export function GetMultipartHederWithToken() {
    const token = localStorage.getItem("token");
    var header = {
        headers: {
            'Content-Type': 'multipart/form-data',
            "x-access-token": `${token}`,
            version: "1.0.0",
        }
    };

    return header;
}