import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
const initialState = {
  year: "2024",
  paymentType: "Expence",
  head: "",
  subhead:"",
  transactionDate: "",
  recieptNo: "",
  amount: "",
  description: "",
  note: "",
  paymentMode: "",
  paymentModeType:"",
  transactionId:"",
  upiId:"",
  bankName:"",
  checkNo:""

};
const AddExpence = () => {
  const [loading , setLoading]=useState(false);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [values, setValues] = useState(initialState);
  const [rcpId, setRcpId]=useState("");
  const { state } = useLocation();
  const startYear = 2022;
  const [years, setYears] = useState([]);
  const [allHead,setAllHead]=useState([]);
  const [allSubhead,setAllSubhead]=useState([]);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    }
  

  const saveUpdateCompany = () => {

    const token= localStorage.getItem('token');
    if (!values.year) {
      toast.error("Please select year !");
      return;
    }

    let api = "addExpence";
    if (values._id) {
      api = "updateExpence";
    }
    
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/${api}`, values, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setValues(initialState);
          // setCompanyId(response.data.data._id);
          toast.success(response.data.message);
          navigate("/expence-listing")
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const getSubheadData = () => {
    const token= localStorage.getItem("token");
  
    axios
      .post(`${BASE_URL}admin/getSubhead`, {}, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",

        },
      })
      .then((response) => {
        if (response.data.success) {
          setAllSubhead(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const getHeadData = () => {
    const token= localStorage.getItem("token");
  
    axios
      .post(`${BASE_URL}admin/getAllExpenceHead`, {}, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",

        },
      })
      .then((response) => {
        if (response.data.success) {
          setAllHead(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  const getAllExpence = () => {
    const token= localStorage.getItem("token");
 
    axios
      .post(`${BASE_URL}admin/getAllExpence`, {}, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",

        },
      })
      .then((response) => {
        if (response.data.success) {
            generateRecieptNo(response.data.data.length);
        }else{
          if(response.data.data.length==0){
            generateRecieptNo(response.data.data.length);
          }
          if(response.data.error_code==461){
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const generateRecieptNo= (l) => {
    setRcpId(`Rcp00${++l}`);
    console.log(`Rcp00${++l}`);
    // console.log("rcpNo",`${employees.length++}`);

  };


  useEffect(() => {
    if (state) {
      setValues({ ...state.item });
      // setCompanyId(state.item._id);
    }
    const yearsArray = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
    getHeadData();
    getSubheadData();
    getAllExpence();
  }, []);

  useEffect(()=>{

  },[])

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <h6 className="element-header"> Expence</h6>
                <div className="element-box">
                  <div>
                    <h5 className="form-header">Add Expence</h5>
                    <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor>Select Year:</label>
                        <select
                          className="form-control"
                          onChange={(e) => handleInputChange(e)}
                          name="year"
                          value={values.year||currentYear.toString()}
                        >
                         {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                      <div className="col-sm-6">
                        <div className="form-group" >
                          <label htmlFor>Payment Type:</label>
                          <select
                          className="form-control"
                          onChange={(e) => handleInputChange(e)}
                          name="paymentType"
                          value={values.paymentType}
                        >
                              <option  value="Income">
                                Income
                              </option>
                              <option  value="Expence">
                               Expence 
                              </option>
                           
                        </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Head</label>
                          <select
                          className="form-control"
                          onChange={(e) => handleInputChange(e)}
                          name="head"
                          value={values.head}
                        >
                             <option  value="">
                                -select head-
                              </option>
                             {
                               allHead?.length>0&&allHead.map((item , index)=>(
                                <option  value={item._id}>
                                {item.name}
                              </option>
                               )) 
                             }
                           
                        </select>
                        
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Subhead</label>
                          <select
                          className="form-control"
                          onChange={(e) => handleInputChange(e)}
                          name="subhead"
                          value={values.subhead}
                        >
                             <option  value="">
                                -select subhead-
                              </option>
                             {
                               allSubhead.filter(item=>item.headId==values.head)?.length>0&&allSubhead.filter(item=>item.headId==values.head).map((item , index)=>(
                                <option  key={index} value={item._id}>
                                {item.name}
                              </option>
                               )) 
                             }
                           
                        </select>
                        
                        </div>
                      </div>
                   
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Reciept No.</label>
                          <input
                              className="form-control"
                              placeholder="Enter Reciept No."
                              type="text"
                              // onChange={(e) => setRcpId(e)}
                              name="rcpId"
                              value={rcpId}
                            />
                        </div>
                      </div>

                   

                      <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor>Description</label>
                        <textarea name="description"
                          className="form-control"
                          placeholder="Enter Description"
                          type="text"
                          onChange={(e) => handleInputChange(e)}
                          value={values.description}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Payment Mode</label>
                          <select
                          className="form-control"
                          onChange={(e) => handleInputChange(e)}
                          name="paymentMode"
                          value={values.paymentMode}
                        >
                             <option  value="">
                               --select--
                              </option>
                             <option  value="Cash">
                                Cash
                              </option>
                              <option  value="Online">
                                Online
                              </option>
                              <option  value="Bank Deposit">
                                Bank Deposit
                              </option>
                              <option  value="Check">
                                Check
                              </option>
                        
                        </select>
                        
                        </div>
                      </div>
                      {
                        values.paymentMode=="Online"&&
                        <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Payment Mode Type</label>
                          <select
                          className="form-control"
                          onChange={(e) => handleInputChange(e)}
                          name="paymentModeType"
                          value={values.paymentModeType}
                        >
                             <option  value="">
                               --select--
                              </option>
                             <option  value="NEFT">
                                NEFT
                              </option>
                              <option  value="UPI">
                                UPI
                              </option>
                        </select>
                        
                        </div>
                      </div>
                      }
                      {
                        values.paymentMode!=""&&
                        <>
                      {
                      values.paymentMode=="Check"&&
                      <>
                        <div className="col-sm-6">
                         <div className="form-group">
                           <label htmlFor>Bank Name</label>
                           <input
                               class="form-control"
                               placeholder="Enter Bank Name"
                               type="text"
                               onChange={(e) => handleInputChange(e)}
                               name="bankName"
                               value={values.bankName}
                             />
                         </div>
                       </div>
                           <div className="col-sm-6">
                           <div className="form-group">
                             <label htmlFor>Check No </label>
                             <input
                                 class="form-control"
                                 placeholder="Enter Check No"
                                 type="text"
                                 onChange={(e) => handleInputChange(e)}
                                 name="checkNo"
                                 value={values.checkNo}
                               />
                           </div>
                         </div>
                         </>
                      
                     }
                          <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Amount</label>
                          <input
                            className="form-control"
                            placeholder="Enter Amount "
                            type="number"
                            name="amount"
                            onChange={(e) => handleInputChange(e)}
                            value={values.amount}
                          />
                        </div>
                      </div>
                         <div className="col-sm-6">
                         <div className="form-group">
                           <label htmlFor>
                            {values.paymentMode=="Check"?"Check":"Transaction"} Date</label>
                           <input
                               class="form-control"
                               placeholder=""
                               type="date"
                               onChange={(e) => handleInputChange(e)}
                               name="transactionDate"
                               value={moment(values.transactionDate).format("YYYY-MM-DD")}
                             />
                         </div>
                       </div>
                        </>
                      
                      }

                     {
                      values.paymentMode=="Online"&&values.paymentModeType=="NEFT"&&
                      
                        <div className="col-sm-6">
                         <div className="form-group">
                           <label htmlFor>Transaction Id</label>
                           <input
                               class="form-control"
                               placeholder="Enter Transaction Id"
                               type="text"
                               onChange={(e) => handleInputChange(e)}
                               name="transactionId"
                               value={values.transactionId}
                             />
                         </div>
                       </div>
                      
                     }
                     {
                      values.paymentMode=="Online"&&values.paymentModeType=="UPI"&&
                      <>
                        <div className="col-sm-6">
                         <div className="form-group">
                           <label htmlFor>UPI Id/UPI Name</label>
                           <input
                               class="form-control"
                               placeholder=""
                               type="text"
                               onChange={(e) => handleInputChange(e)}
                               name="upiId"
                               value={values.upiId}
                             />
                         </div>
                       </div>
                        <div className="col-sm-6">
                         <div className="form-group">
                           <label htmlFor>Transaction Id</label>
                           <input
                               class="form-control"
                               placeholder="Enter Transaction Id"
                               type="text"
                               onChange={(e) => handleInputChange(e)}
                               name="transactionId"
                               value={values.transactionId}
                             />
                         </div>
                       </div>
                     
                      </>
                     }
                   
                     
                    </div>

                    <div className="form-buttons-w">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => saveUpdateCompany()}
                      >
                        {
                          loading? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            "Submit"
                          )
                        }
                        
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* /////////////// category listing  start */}

   
    </Layout>
  );
};

export default AddExpence;
