import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import { Link } from "react-browser-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const initialState = {
  name: "",
  email:"",
  mobile_no:"1234567890",
  password:"",
  businessTitle:"",

  CategoryId: "",
  SubCategoryId: "",
  gstin_no:"",

  country:"",
  state:"",
  city:"",
  location:"",
  pincode:"",
  area:"",
  businessAddress:"",
  googleMap:"",

  whatsappNo:"",
  website:"",
  businessEmail:"",
  facebook:"",
  instagram:"",
  twitter:"",
  linkedin:"",
  youtube:"",
  mondayOpening:"",
  mondayClosing:"",
  tuesdayOpening:"",
  tuesdayClosing:"",
  wednesdayOpening:"",
  wednesdayClosing:"",
  thursdayOpening:"",
  thursdayClosing:"",
  fridayOpening:"",
  fridayClosing:"",
  saturdayOpening:"",
  saturdayClosing:"",
  

  shortDescription:"",

  accountNo:"",
  accountHolderName:"",
  branchName:"",
  bankName:"",
  ifscCode:"",


  documentType:"",
  documentIdNo:"",
  // status:"Active",
};
function AddBusiness() {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state);

  const [googleMap, setGoogleMap] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [tags, setTags] = useState([]);
  const [allKeywords, setAllKeywords] = useState([]);
  // const [inputValue, setInputValue] = useState('');
  const [metaSuggestions, setMetaSuggestions] = useState([]);
  const suggestionsRef = useRef(null);
  const [longDescription, setLongDescription] = useState("");
  const [values, setValues] = useState(initialState);
  const [subCategory, setSubCategory] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [activeTab, setActiveTab] = useState("firstStep");
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [documentImage, setDocumentImage] = useState("");
  const [businessId, setBusinessId] = useState("");

  const [categoryId, setCategoryId] = useState("");

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const handleTabClick = (tabName) => {
    console.log("okkkk", tabName);
    if (tabName === "firstStep") {
      if(!values.name){
     toast.error("Name is required");
     return false;
      }
      setActiveTab(tabName);
    }
    if (tabName === "secondStep") {
      // if (!values.name) {
      //   toast.error("Please enter product name!");
      //   return;
      // }
      // if (!values.CategoryId) {
      //   toast.error("Please select category!");
      //   return;
      // }
      // if (!values.title) {
      //   toast.error("Please enter title!");
      //   return;
      // }
      // if (!values.shortDescription) {
      //   toast.error("Please enter description/meta description!");
      //   return;
      // }
      // if (!file1) {
      //   toast.error("Please select first image");
      //   return;
      // }
      // if (!file2) {
      //   toast.error("Please select second image");
      //   return;
      // }
      // if (!file3) {
      //   toast.error("Please select third image");
      //   return;
      // }

      setActiveTab(tabName);
    } else if (tabName === "thirdStep") {
      // if (!values.price) {
      //   toast.error("Please enter price!");
      //   return;
      // }
      // if (!values.mrp) {
      //   toast.error("Please enter mrp!");
      //   return;
      // }
      setActiveTab(tabName);
    } else if (tabName === "fourthStep") {
      setActiveTab(tabName);
    } else if (tabName === "fifthStep") {
      setActiveTab(tabName);
    }else if(tabName === "sixthStep"){
      setActiveTab(tabName);
    }else if(tabName==="seventhStep"){
      setActiveTab(tabName);
    }
  };

  const getCategoryData = () => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}admin/getCategory`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setAllCategory(response.data.data);
          console.log("setAllCategory", response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getSubCategoryData = () => {
    const token = localStorage.getItem("token");
    if (values.CategoryId) {
      axios
        .get(`${BASE_URL}admin/subCategoryByCategory/${values.CategoryId}`, {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        })
        .then((response) => {
          if (response.data.success) {
            setSubCategory(response.data.data);
            console.log("setSubCategory", response.data.data);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mrp" || name === "price") {
      setValues({
        ...values,
        [name]: parseInt(value),
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleClick = (fileInputRef) => {
    fileInputRef.current.click(); // Simulating a click on the file input
  };
  const addImage = (e) => {
    setFile1(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(e.target.files[0]);
      setLogo((e) => [newImageSrc]);
    }
  };
  const addImage2 = (e) => {
    setFile2(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(e.target.files[0]);
      setBanner((e) => [newImageSrc]);
    }
  };
  const addImage3 = (e) => {
    setFile3(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(e.target.files[0]);
      setDocumentImage((e) => [newImageSrc]);
    }
  };
  // add update product
  const SaveProduct = (step) => {
   console.log(values);
    const token = localStorage.getItem("token");
    const fd = new FormData();
    let api = "addBusiness";
    if (businessId) {
      api = "updateBusiness";
    }

    fd.append("name",values.name);
    fd.append("email",values.email);
    fd.append("mobile_no",values.mobile_no);
    fd.append("password",values.password);
    fd.append("businessTitle",values.businessTitle);
    fd.append("gstin_no",values.gstin_no);
    fd.append("country",values.country);
    fd.append("state",values.state);
    fd.append("city",values.city);
    fd.append("location",values.location);
    fd.append("pincode",values.pincode);
    fd.append("area",values.area);
    fd.append("businessAddress",values.businessAddress);
    fd.append("googleMap",values.googleMap);
    fd.append("whatsappNo",values.whatsappNo);
    fd.append("website",values.website);
    fd.append("businessEmail",values.businessEmail);
    fd.append("facebook",values.facebook);
    fd.append("instagram",values.instagram);
    fd.append("twitter",values.twitter);
    fd.append("linkedin",values.linkedin);
    fd.append("youtube",values.youtube);
    fd.append("mondayOpening",values.mondayOpening);
    fd.append("mondayClosing",values.mondayClosing);
    fd.append("tuesdayOpening",values.tuesdayOpening);
    fd.append("tuesdayClosing",values.tuesdayClosing);
    fd.append("wednesdayOpening",values.wednesdayOpening);
    fd.append("wednesdayClosing",values.wednesdayClosing);
    fd.append("thursdayOpening",values.thursdayOpening);
    fd.append("thursdayClosing",values.thursdayClosing);
    fd.append("fridayOpening",values.fridayOpening);
    fd.append("fridayClosing",values.fridayClosing);
    fd.append("saturdayOpening",values.saturdayOpening);
    fd.append("saturdayClosing",values.saturdayClosing);


    fd.append("accountNo",values.accountNo);
    fd.append("accountHolderName",values.accountHolderName);
    fd.append("bankName",values.bankName);
    fd.append("branchName",values.branchName);
    fd.append("ifscCode",values.ifscCode);


    fd.append("documentType",values.documentType);
    fd.append("documentIdNo",values.documentIdNo);
  
    // fd.append("status", values.status);




    if (tags) {
      fd.append("metaKeyword", JSON.stringify(tags));
    }
    if (values.shortDescription) {
      fd.append("shortDescription", values.shortDescription);
    }
    if (longDescription) {
      fd.append("longDescription", longDescription);
    }
    fd.append("CategoryId", values.CategoryId);
    values.SubCategoryId != null &&
      fd.append("SubCategoryId", values.SubCategoryId);

    
    file1 != null && fd.append("logo", file1[0]);
    file2 != null && fd.append("banner", file2[0]);
    file3 != null && fd.append("documentImage", file3[0]);

    if (businessId) {
      fd.append("id", businessId);
    }
    axios
      .post(`${BASE_URL}admin/${api}`, fd, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          if (step === "final") {
            navigate("/product-listing");
          }
          handleTabClick(step);
          setBusinessId(response.data.data._id);
          setCategoryId(response.data.data.CategoryId);
          setValues(initialState);
          toast.success(response.data.message);
          fileInputRef1.current.value = "";
          fileInputRef2.current.value = "";
          fileInputRef3.current.value = "";
          addKeyword();
          setValues({ ...response.data.data });
          setTags(response.data.data.metaKeyword);
          response.data.data.image1 ?? setLogo(response.data.data.logo);
          response.data.data.image2 ?? setBanner(response.data.data.banner);
          response.data.data.image3 ?? setDocumentImage(response.data.data.documentImage);
          response.data.data.image1 ?? setFile1(response.data.data.logo);
          response.data.data.image2 ?? setFile2(response.data.data.banner);
          response.data.data.image3 ?? setFile3(response.data.data.documentImage);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // keyword start
  const handleDocumentClick = (event) => {
    // Close suggestions if click occurred outside of input and suggestions list
    if (
      !event.target.closest(".autocomplete-input") &&
      suggestionsRef.current
    ) {
      setMetaSuggestions([]);
    }
  };
  const handleMetaInputChange = (event) => {
    const value = event.target.value;
    setMetaKeyword(value);

    // Filter suggestions based on input value
    const filteredSuggestions = allKeywords.filter((suggestion) =>
      suggestion.keyword.toLowerCase().startsWith(value.toLowerCase())
    );
    setMetaSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    setMetaKeyword(suggestion.keyword);
    setMetaSuggestions([]);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && metaKeyword.trim() !== "") {
      setTags([...tags, metaKeyword.trim()]);
      setMetaKeyword("");
      console.log(tags);
    }
  };
  const removeTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const addKeyword = async () => {
    const token = localStorage.getItem("token");
    let newArr = [];
    for await (const val of tags) {
      let found = false;
      for await (const keywordObj of allKeywords) {
        if (keywordObj.keyword === val) {
          found = true;
          break;
        }
      }
      if (!found) {
        newArr.push(val);
      }
    }
    let obj = {
      keyword: newArr,
    };
    axios
      .post(`${BASE_URL}admin/addMetaKeyword`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getKeywords = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getMetaKeywords`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setAllKeywords(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  // keyword end
  useEffect(() => {
    getCategoryData();
    getKeywords();
    if (state) {
      setValues({ ...state.item });
      setTags(state.item.metaKeyword);
      setLogo(state.item.logo);
      setBanner(state.item.banner);
      setDocumentImage(state.item.documentImage);
      setFile1(state.item.image1);
      setFile2(state.item.image2);
      setFile3(state.item.image3);
      setLongDescription(state.item.longDescription);
      setBusinessId(state.item._id);
    }

    document.addEventListener("click", handleDocumentClick);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    setSubCategory([]);
    getSubCategoryData();
  }, [values.CategoryId]);

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div>
                <div className="steps-w w-100">
                  <div className="step-triggers">
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${activeTab=="firstStep"?"active":""}`}
                      onClick={() => handleTabClick("firstStep")}
                    >
                      Basic Information
                    </a>
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${activeTab=="secondStep"?"active":""}`}
                      onClick={() => handleTabClick("secondStep")}
                    >
                      Business Details
                    </a>
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${activeTab=="thirdStep"?"active":""}`}
                      onClick={() => handleTabClick("thirdStep")}
                    >
                      Business Hours
                    </a>
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${activeTab=="fourthStep"?"active":""}`}
                      onClick={() => handleTabClick("fourthStep")}
                    >
                      Business Description
                    </a>
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${activeTab=="fifthStep"?"active":""}`}
                      onClick={() => handleTabClick("fifthStep")}
                    >
                      Business Keyword
                    </a>

                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${activeTab=="sixthStep"?"active":""}`}
                      onClick={() => handleTabClick("sixthStep")}
                    >
                      Bank Details
                    </a>
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${activeTab=="seventhStep"?"active":""}`}
                      onClick={() => handleTabClick("seventhStep")}
                    >
                      KYC details
                    </a>
                  </div>
                  <div className="step-contents">
                    <div
                      className={`step-content  ${activeTab=="firstStep"?"active":""}`}
                      id="stepContent1"
                      style={{
                        display: activeTab == "firstStep" ? "block " : "none",
                      }}
                    >
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Name</label>
                            <input
                              className="form-control"
                              placeholder="Enter Name"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="name"
                              value={values.name}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Email:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Email"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="email"
                              value={values.email}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Mobile Number:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Mobile No."
                              type="Number"
                              onChange={(e) => handleInputChange(e)}
                              name="mobile_no"
                              value={values.mobile_no}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Password:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Passowrd."
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="password"
                              value={values.password}
                            />
                          </div>
                        </div>
                      </div>
                     

                    
                      <div className=" text-right">
                        <button
                          className="btn btn-primary text-white"
                          onClick={() => SaveProduct("secondStep")}
                          // onClick={}
                        >
                          Save & Continue
                        </button>
                      </div>
                    </div>
                    <div
                      className={`step-content ${activeTab=="secondStep"?"active":""}`}
                      style={{
                        display: activeTab == "secondStep" ? "block " : "none",
                      }}
                    >
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Business Title</label>
                            <input
                              className="form-control"
                              placeholder="Enter Business Title"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="businessTitle"
                              value={values.businessTitle}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label> Category</label>
                            <select
                              className="form-control "
                              onChange={(e) => handleInputChange(e)}
                              name="CategoryId"
                              value={values.CategoryId}
                            >
                              <option value="">--select--</option>

                              {allCategory?.map((item, index) => (
                                <option key={item._id} value={item._id}>
                                  {item.name}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Sub category</label>
                            <select
                              className="form-control "
                              onChange={(e) => handleInputChange(e)}
                              name="SubCategoryId"
                              value={values.SubCategoryId}
                            >
                              <option value="">--select--</option>
                              {subCategory?.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> GSTIN No.</label>
                            <input
                              className="form-control"
                              placeholder="Enter GSTIN No."
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="gstin_no"
                              value={values.gstin_no}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Country</label>
                            <select
                              className="form-control "
                              onChange={(e) => handleInputChange(e)}
                              name="country"
                              value={values.country}
                            >
                              <option value="">--select--</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>State</label>
                            <select
                              className="form-control "
                              onChange={(e) => handleInputChange(e)}
                              name="state"
                              value={values.state}
                            >
                              <option value="">--select--</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>City</label>
                            <select
                              className="form-control "
                              onChange={(e) => handleInputChange(e)}
                              name="city"
                              value={values.city}
                            >
                              <option value="">--select--</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Location</label>
                            <select
                              className="form-control "
                              onChange={(e) => handleInputChange(e)}
                              name="location"
                              value={values.location}
                            >
                              <option value="">--select--</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Pincode</label>
                            <select
                              className="form-control "
                              onChange={(e) => handleInputChange(e)}
                              name="pincode"
                              value={values.pincode}
                            >
                              <option value="">--select--</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Area:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Area:"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="area"
                              value={values.area}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Business Address:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Business Address:"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="businessAddress"
                              value={values.businessAddress}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12">
                          <label>Google Map</label>
                          <div className="form-group">
                            <CKEditor
                              editor={ClassicEditor}
                              data={googleMap}
                              onReady={(editor) => {
                                console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(e, editor) => {
                                let data = editor.getData();
                                setGoogleMap(data);
                              }}
                              onBlur={(event, editor) => {
                                console.log("Blur.", editor);
                              }}
                              onFocus={(event, editor) => {
                                console.log("Focus.", editor);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Whatsapp No.</label>
                            <input
                              type="number"
                              className="form-control"
                              onChange={(e) => handleInputChange(e)}
                              name="whatsappNo"
                              value={values.whatsappNo}
                            ></input>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Website:</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => handleInputChange(e)}
                              name="website"
                              value={values.website}
                            ></input>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Business Email:</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => handleInputChange(e)}
                              name="businessEmail"
                              value={values.businessEmail}
                            ></input>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Facebook:</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => handleInputChange(e)}
                              name="facebook"
                              value={values.facebook}
                            ></input>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Instagram:</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => handleInputChange(e)}
                              name="instagram"
                              value={values.instagram}
                            ></input>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Twitter:</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => handleInputChange(e)}
                              name="twitter"
                              value={values.twitter}
                            ></input>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Linkedin:</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => handleInputChange(e)}
                              name="linkedin"
                              value={values.linkedin}
                            ></input>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>youtube:</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => handleInputChange(e)}
                              name="youtube"
                              value={values.youtube}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="element-wrapper">
                            <div
                              className="element-box"
                              onClick={() => handleClick(fileInputRef1)}
                            >
                              <form
                                className="dropzone p-0 pt-2"
                                id="my-awesome-dropzone"
                              >
                                <img
                                  src={logo}
                                  className={`rounded-1 col-lg-2 col-3 img-fluid  ${
                                    logo ? "d-block" : "d-none"
                                  }`}
                                  style={{
                                    width: "100%",
                                    aspectRatio: 4 / 4,
                                    objectFit: "cover",
                                  }}
                                  alt=""
                                />
                                <div
                                  className={`dz-message ${
                                    logo ? "d-none" : "d-block"
                                  }`}
                                >
                                  <div>
                                    <h5> Click to Upload Image.</h5>
                                  </div>
                                </div>
                                <input
                                  type="file"
                                  ref={fileInputRef1}
                                  style={{ display: "none" }}
                                  name="logo"
                                  onChange={addImage}
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                      
                      </div>

                      <div className="row">
                        <div className="col-sm-3">
                          <div className="element-wrapper">
                            <div
                              className="element-box"
                              onClick={() => handleClick(fileInputRef2)}
                            >
                              <form
                                className="dropzone p-0 pt-2"
                                id="my-awesome-dropzone"
                              >
                                <img
                                  src={banner}
                                  className={`rounded-1 col-lg-2 col-3 img-fluid  ${
                                    banner ? "d-block" : "d-none"
                                  }`}
                                  style={{
                                    width: "100%",
                                    aspectRatio: 4 / 4,
                                    objectFit: "cover",
                                  }}
                                  alt=""
                                />
                                <div className={`dz-message ${banner}`}>
                                  <div>
                                    <h5> Click to Upload Image.</h5>
                                  </div>
                                </div>
                                <input
                                  type="file"
                                  ref={fileInputRef2}
                                  style={{ display: "none" }}
                                  name="banner"
                                  onChange={addImage2}
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                      <div className="form-buttons-w text-right">
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => handleTabClick("firstStep")}
                          >
                            Back
                          </button>
                        </span>
                        &nbsp;&nbsp;{" "}
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                           onClick={()=>SaveProduct("thirdStep")}
                          >
                            Save
                          </button>
                        </span>
                        &nbsp;&nbsp;
                      </div>
                   
                    </div>
                    <div
                      className={`step-content ${activeTab=="thirdStep"?"active":""}`}
                      id="stepContent3"
                      style={{
                        display: activeTab == "thirdStep" ? "block" : "none",
                      }}
                    >
                     
                     <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Monday Open At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Monday Opening Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="mondayOpening"
                              value={values.mondayOpening}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Monday Close At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Monday Closing Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="mondayClosing"
                              value={values.mondayClosing}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Tuesday Open At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Tuesday Opening Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="tuesdayOpening"
                              value={values.tuesdayOpening}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Tuesday Close At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Tuesday Closing Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="tuesdayClosing"
                              value={values.tuesdayClosing}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Wednesday Open At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Wednesday Opening Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="wednesdayOpening"
                              value={values.wednesdayOpening}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Wednesday Close At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Wednesday Closing Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="wednesdayClosing"
                              value={values.wednesdayClosing}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Thursday Open At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Thursday Opening Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="thursdayOpening"
                              value={values.thursdayOpening}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Thursday Close At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Thursday Closing Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="thursdayClosing"
                              value={values.thursdayClosing}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Friday Open At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Friday Opening Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="fridayOpening"
                              value={values.fridayOpening}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Friday Close At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Friday Closing Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="fridayClosing"
                              value={values.fridayClosing}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Saturday Open At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Saturday Opening Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="saturdayOpening"
                              value={values.saturdayOpening}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor> Saturday Close At</label>
                            <input
                              className="form-control"
                              placeholder="Enter Saturday Closing Time"
                              type="time"
                              onChange={(e) => handleInputChange(e)}
                              name="saturdayClosing"
                              value={values.saturdayClosing}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-buttons-w text-right">
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => handleTabClick("secondStep")}
                          >
                            Back
                          </button>
                        </span>
                        &nbsp;&nbsp;{" "}
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                           onClick={()=>SaveProduct("fourthStep")}
                          >
                            Save
                          </button>
                        </span>
                        &nbsp;&nbsp;
                      </div>
                    </div>
                    <div
                      className={`step-content ${activeTab=="fourthStep"?"active":""}`}
                      style={{
                        display: activeTab == "fourthStep" ? "block " : "none",
                      }}
                    >
                    
                    <div className="row">
                        <div className="col-sm-12">
                          <label>Short Description</label>
                          <div className="form-group">
                            <textarea
                            className="form-control"
                              onChange={(e) => handleInputChange(e)}
                              name="shortDescription"
                              value={values.shortDescription}
                              rows={4}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12">
                          <label>Long Description</label>
                          <div className="form-group">
                            <CKEditor
                              editor={ClassicEditor}
                              data={longDescription}
                              onReady={(editor) => {
                                console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(e, editor) => {
                                let data = editor.getData();
                                setLongDescription(data);
                              }}
                              onBlur={(event, editor) => {
                                console.log("Blur.", editor);
                              }}
                              onFocus={(event, editor) => {
                                console.log("Focus.", editor);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      
                      <div className="form-buttons-w text-right">
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => handleTabClick("thirdStep")}
                          >
                            Back
                          </button>
                        </span>
                        &nbsp;&nbsp;{" "}
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                           onClick={()=>SaveProduct("fifthStep")}
                          >
                            Save
                          </button>
                        </span>
                        &nbsp;&nbsp;
                      </div>
                    </div>
                    <div
                      className={`step-content ${activeTab=="fifthStep"?"active":""}`}
                      style={{
                        display: activeTab == "fifthStep" ? "block " : "none",
                      }}
                    >
                      <div className="row">
                        <div className="form-group autocomplete-input">
                          <label htmlFor> Meta Keyword</label>
                          <br />
                          <input
                            type="text"
                            value={metaKeyword}
                            className="form-control"
                            onChange={(e) => handleMetaInputChange(e)}
                            onKeyDown={handleInputKeyDown}
                            placeholder="Type and press Enter to add tags"
                          />
                          {metaSuggestions.length > 0 && (
                            <ul
                              className="autocomplete-input mb-0"
                              style={{
                                listStyleType: "none",
                                padding: "10px 20px",
                                borderRadius: "10px",
                                backgroundColor: "#efefef",
                              }}
                              ref={suggestionsRef}
                            >
                              {metaSuggestions.map((suggestion, index) => (
                                <li
                                  key={index}
                                  onClick={() =>
                                    handleSuggestionClick(suggestion)
                                  }
                                >
                                  {suggestion.keyword}
                                </li>
                              ))}
                            </ul>
                          )}

                          <br />
                          {tags.map((tag, index) => (
                            <span
                              key={index}
                              className="tag bg-primary rounded-pill p-2 text-white "
                            >
                              {tag}
                              <button
                                className="bg-primary border-0 text-white"
                                onClick={() => removeTag(index)}
                              >
                                x
                              </button>
                            </span>
                          ))}
                        </div>
                      </div>

                      <div className="form-buttons-w text-right">
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => handleTabClick("fourthStep")}
                          >
                            Back
                          </button>
                        </span>
                        &nbsp;&nbsp;{" "}
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                           onClick={()=>SaveProduct("sixthStep")}
                          >
                            Save
                          </button>
                        </span>
                        &nbsp;&nbsp;
                      </div>
                    </div>

                    <div
                       className={`step-content ${activeTab=="sixthStep"?"active":""}`}
                      style={{
                        display: activeTab == "sixthStep" ? "block " : "none",
                      }}
                    >
                     <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Account No.:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Account Number"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="accountNo"
                              value={values.accountNo}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Account Holder Name:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Account Holder Name"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="accountHolderName"
                              value={values.accountHolderName}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Bank Name:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Bank Name"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="bankName"
                              value={values.bankName}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Branch Name:</label>
                            <input
                              className="form-control"
                              placeholder="Enter Branch Name"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="branchName"
                              value={values.branchName}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>IFSC Code:</label>
                            <input
                              className="form-control"
                              placeholder="Enter IFSC Code"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="ifscCode"
                              value={values.ifscCode}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-buttons-w text-right">
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => handleTabClick("fifthStep")}
                          >
                            Back
                          </button>
                        </span>
                        &nbsp;&nbsp;{" "}
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                           onClick={()=>SaveProduct("seventhStep")}
                          >
                            Save
                          </button>
                        </span>
                        &nbsp;&nbsp;
                      </div>
                     
                    </div>

                    <div
                        className={`step-content ${activeTab=="seventhStep"?"active":""}`}
                      style={{
                        display: activeTab == "seventhStep" ? "block " : "none",
                      }}
                    >
                    <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Document Type:</label>
                            <select
                              className="form-control "
                              onChange={(e) => handleInputChange(e)}
                              name="documentType"
                              value={values.documentType}
                            >
                              <option value="">--select--</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Document Id No.:</label>
                            <input type="text"
                              className="form-control "
                              onChange={(e) => handleInputChange(e)}
                              name="documentIdNo"
                              value={values.documentIdNo}
                            >
                            </input>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Document Id No.:</label>
                            <input type="text"
                              className="form-control "
                              onChange={(e) => handleInputChange(e)}
                              name="documentIdNo"
                              value={values.documentIdNo}
                            >
                            </input>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-3">
                          <div className="element-wrapper">
                            <div
                              className="element-box"
                              onClick={() => handleClick(fileInputRef3)}
                            >
                              <form
                                className="dropzone p-0 pt-2"
                                id="my-awesome-dropzone"
                              >
                                <img
                                  src={documentImage}
                                  className={`rounded-1 col-lg-2 col-3 img-fluid  ${
                                    documentImage ? "d-block" : "d-none"
                                  }`}
                                  style={{
                                    width: "100%",
                                    aspectRatio: 4 / 4,
                                    objectFit: "cover",
                                  }}
                                  alt=""
                                />
                                <div
                                  className={`dz-message ${
                                    documentImage ? "d-none" : "d-block"
                                  }`}
                                >
                                  <div>
                                    <h5> Click to Upload Image.</h5>
                                  </div>
                                </div>
                                <input
                                  type="file"
                                  ref={fileInputRef3}
                                  style={{ display: "none" }}
                                  name="documentImage"
                                  onChange={addImage3}
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-sm-4">
                          <div className="form-group">
                            <label htmlFor>Image Alt Text</label>
                            <input
                              className="form-control"
                              placeholder="Enter Title"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="image2_alttext"
                              value={values.image2_alttext}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label htmlFor>Image Title Text</label>
                            <input
                              className="form-control"
                              placeholder="Enter Title"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="image2_titleText"
                              value={values.image2_titleText}
                            />
                          </div>
                        </div> */}
                      </div>

                      <div className=" text-right">
                        <button
                          className="btn btn-primary text-white"
                          onClick={() => SaveProduct("seventhStep")}
                          // onClick={}
                        >
                          Save & Continue
                        </button>
                      </div>
                    </div>

                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddBusiness;
