import React from 'react'

function LoanApproved({
    handleInputChange,
    values,
    SaveLoan,
    loanId,
    loading,
    setActiveTab,
}) {
  return (
   <>
     <div className="row">
                          {values?.history?.companyDetails?.length > 0 && (
                            <fieldset>
                              <legend>
                                <span>Loan Approved Company List</span>
                              </legend>

                              <div className="table-responsive">
                                <table className="table table-lightborder">
                                  <thead>
                                    <tr>
                                      <th>Company Type</th>
                                      <th>Comapny Name</th>
                                      <th>Comapny Coordinator Name</th>
                                      <th>Status</th>

                                      {/* <th className="text-center">Actions</th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {values?.history && (
                                      <tr>
                                        <td>
                                          {
                                            values?.history.companyDetails[0]
                                              ?.company_type
                                          }
                                        </td>
                                        <td>
                                          {
                                            values?.history.companyDetails[0]
                                              ?.company_name
                                          }
                                        </td>

                                        <td>
                                          <div>
                                            <p className="m-1">
                                              {
                                                values?.history
                                                  .companyDetails[0]
                                                  ?.company_coordinator_name
                                              }
                                            </p>
                                            <p>
                                              {
                                                values?.history
                                                  .companyDetails[0]
                                                  ?.company_coordinator_mobile_no
                                              }
                                            </p>
                                          </div>
                                        </td>

                                        <td>{values?.history?.status}</td>
                                        {/* <td>
                                        <button className="text-success border-0 bg-white ps-2" 
                                        // onClick={()=>ViewHistoryFn(item)}
                                        >
                                          View
                                        </button>
                                      </td> */}
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </fieldset>
                          )}
                        </div>
                        <fieldset>
                          <legend>
                            <span>Payment Details</span>
                          </legend>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Loan Sanction</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Sanction"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="sanction"
                                  value={values?.sanction}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Recieved Payment</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="payment"
                                  value={values?.payment}
                                />
                              </div>
                            </div>

                          
                          </div>
                        </fieldset>
                           {/* //// deduction expences //// */}
                           <fieldset>
                          <legend>
                            <span> Deduction Expences</span>
                          </legend>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Processing Fee</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="processing_fee"
                                  value={values.processing_fee}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label for="">GST Fee</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="gst_fee"
                                  value={values.gst_fee}
                                />
                              </div>
                            </div>
                           

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">
                                  Loan Cover Insurance Amount
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="insuranceAmount"
                                  value={values.insuranceAmount}
                                />
                              </div>
                            </div>
                         

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label for="">Document Fee</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="document_fee"
                                  value={values.document_fee}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label for="">Stamp Fee</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="stamp_fee"
                                  value={values.stamp_fee}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Valuation Fee</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="valuation_fee"
                                  value={values.valuation_fee}
                                />
                              </div>
                            </div>

                            <div className="col-sm-12 ">
                              <div
                                className="d-flex justify-content-between"
                                style={{
                                  backgroundColor: "#e4e4e4",
                                  padding: "10px",
                                }}
                              >
                                <div>
                                  <b>Total Deduction Expences:</b>
                                </div>
                                {values.name ? (
                                  <div>
                                    <b>
                                      <i className="fa fa-inr"></i>
                                      {values.totalDeduction}
                                    </b>
                                  </div>
                                ) : (
                                  <div>0</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        {/* //// RTO  //// */}
                        <fieldset className="mt-4">
                          <legend>
                            <span> RTO Expences</span>
                          </legend>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Vehical Insurance</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="vehicalInsurance"
                                  value={values.vehicalInsurance}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">T.O Fees</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="TO_fees"
                                  value={values.TO_fees}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label for="">T.O + Address Change Fees </label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="TO_AddressChange_Fees"
                                  value={values.TO_AddressChange_Fees}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label for="">C/O Fees</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="CO_fees"
                                  value={values.CO_fees}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">HSRP Fees</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="HSRP_Fees"
                                  value={values.HSRP_Fees}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">DRC Fees</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="DRC_Fees"
                                  value={values.DRC_Fees}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Tax</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="Tax"
                                  value={values.Tax}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Open Bank Account</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="Open_BankAccount"
                                  value={values.Open_BankAccount}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Stamp Fees</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="RTO_Stamp_Fees"
                                  value={values.RTO_Stamp_Fees}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Form 34</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="form_34"
                                  value={values.form_34}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Form 35</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="form_35"
                                  value={values.form_35}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Vehical Renewal</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="vehical_renewal"
                                  value={values.vehical_renewal}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Form 31 (DEATH)</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="form_31"
                                  value={values.form_31}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Other Expence</label>
                                <input
                                  className="form-control"
                                  placeholder="0"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="otherExpences"
                                  value={values.otherExpences}
                                />
                              </div>
                            </div>

                            {/* <hr/> */}
                            <div className="col-sm-12 ">
                              <div
                                className="d-flex justify-content-between"
                                style={{
                                  backgroundColor: "#e4e4e4",
                                  padding: "10px",
                                }}
                              >
                                <div>
                                  <b>Total RTO Expences</b>
                                </div>
                                {values.name ? (
                                  <div>
                                    <b>
                                      <i className="fa fa-inr"></i>{" "}
                                      {values.totalRtoExpence}
                                    </b>
                                  </div>
                                ) : (
                                  <div>0</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </fieldset>
                     

                        <div className="form-buttons-w text-right">
                          <span className=" text-right">
                            <button
                              className="btn btn-primary text-white"
                              onClick={() => setActiveTab("thirdStep")}
                            >
                              Back
                            </button>
                          </span>
                          &nbsp;&nbsp;{" "}
                          <span className=" text-right">
                            <button
                              className="btn btn-primary text-white"
                              onClick={() => SaveLoan("final")}
                            >
                              {loading ? (
                                <span className="spinner-border spinner-border-sm"></span>
                              ) : (
                                <span>Save & Continue</span>
                              )}
                            </button>
                          </span>
                          &nbsp;&nbsp;
                        </div>
   </>
  )
}

export default LoanApproved