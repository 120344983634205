import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../../components/Layout";
import moment from "moment";


function PaymentHistory() {
  const [paymentHistory , setPaymentHistory]=useState([]);
  const [selectedPersonType, setSelectedPersonType]=useState([]);
  const [agentId, setAgentId]=useState([]);
  const navigate= useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages]=useState(1)
const BASE_URL= process.env.REACT_APP_BASE_URL;
  const getData = () => {
    const token= localStorage.getItem('token');
    const obj = {
      page: currentPage,
      limit: 8,
    };
    axios
      .post(`${BASE_URL}admin/getRtoAgentApplicantPaymentHistory`, obj, {
        headers: {
          'x-access-token':`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
            setPaymentHistory(response.data.data.filter(record => record.agentId));
          setPages(response.data.remainingCount);
        }else{
          if(response.data.error_code==461){
            navigate("/login")
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const deleteRTPAgentPaymentHistory=(id)=>{
    const token= localStorage.getItem('token');
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    axios.delete(`${BASE_URL}admin/deleteRTOAgentPaymentHistory/${id}`,{
      headers:{
        "x-access-token":`${token}`,
        "Content-Type":"Application/json",
        version:"1.0.0"
      }
    }).then((response)=>{
      if(response.data.success){
        toast.error(response.data.message);
        getData();
      }
    }).catch((err)=>{
      console.log(err.message);
    })
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(()=>{
    getData();
  },[currentPage])
  

  useEffect(() => {
    let filteredData = [];
console.log("paymentHistory",paymentHistory);
    if (selectedPersonType !== "Agent") {
      filteredData = paymentHistory.filter(payment => payment.rtoId != null);
      console.log('Filtered Data for non-Agent:', filteredData);
    } else {
      filteredData = paymentHistory.filter(payment => payment.agentId != null);
      console.log('Filtered Data for Agent:', filteredData);
    }

    setPaymentHistory(filteredData);
  }, [selectedPersonType]);
  return (
    <Layout>
    <div className="content-i">
      <div className="content-box">
        <div className="element-wrapper">
          <div className="element-box">
          <div className="row d-flex justify-content-between">
              <div className="col">
              <h5 className="form-header text-bold">RTO Payment Listing</h5>
              </div>
              <div className="col-3 text-end">
              <select className="form-control" name="selectedPersonType" value={selectedPersonType} onChange={(e)=>setSelectedPersonType(e.target.value)}>
              <option value="">--Select Agent/Payment--</option>
              <option value="Agent">Agent</option>
              <option value="Applicant">Applicant</option>
             </select>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-lightborder">
                <thead>
                  <tr>
                    <th>Agent Name</th>
                    <th>Amount</th>
                    <th>Other Amount</th>
                    <th>Payment Mode</th>
                    <th>Transaction Date</th>
                    <th>Transaction Id</th>

                    <th>Remark</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    paymentHistory?.length>0?paymentHistory?.map((item,index)=>(
                      <tr key={index}>
                      <td>
                        {item.agentData[0]?.name}
                        <br/>
                        {item.agentData[0]?.mobile_no}
                        </td>
                      <td>{item.amount}</td>

                      <td>{item.otherAmount}</td>
                      <td>{item.paymentMode}</td>
  
                      <td>{moment(item.transactionDate).format('YYYY-MM-DD')}</td>
                      <td>{item.transactionId}</td>
                      <td>{item.remark
}</td>
<td>

<button className="border-0 bg-white">
                          <i className="os-icon os-icon-pencil-2" 
                          onClick={() =>
                            navigate("/rto-agent-payment", { state: { item } })
                          }
                          />
                        </button>
  
                        <button  className="text-danger border-0 bg-white ps-2" onClick={()=>deleteRTPAgentPaymentHistory(item._id)} >
                          <i className="os-icon os-icon-database-remove" />
                        </button>
</td>
                
                    
                    </tr>
                    )):<div></div>
                  }
               
                </tbody>
              </table>
            </div>
          </div>
          <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      <>
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      </>
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
        </div>
      </div>
    </div>
    </Layout>
  );
}

export default PaymentHistory;
