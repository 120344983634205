import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { getCompany } from "../../services/companies/company";
const initialWithDrawalState = {
  withdrawalTo: "",
  accountHolderName: "",
  bankName: "",
  accountNumber: "",
  IfscCode: "",

  transactionDate: "",
  amount: 0,
  remark: "",
  _id: "",
  finance_company: "",
  withdrawalTo2: "",
};
function PaymentWithdrawal({
  loanId,
  setActiveTab,
  loading,
  setAllCompanies,
  handleInputChange,
  values,
  allCompanies,
}) {
  const navigate = useNavigate();
  const [withdrawalValues, setWithdrawalValues] = useState(
    initialWithDrawalState
  );
  const [paymentWithdrawals, setPaymentWithdrawals] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const handleWithdrawInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setWithdrawalValues({
        ...withdrawalValues,
        image: [...withdrawalValues.image, ...files],
      });
    } else {
      setWithdrawalValues({
        ...withdrawalValues,
        [name]: value,
      });
    }
  };
  const getPaymentWithDrawals = () => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}admin/getAllPaymentWithdrawals`,
        { loanId: loanId },
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setPaymentWithdrawals(response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addPaymentWithDrawals = () => {
    const token = localStorage.getItem("token");
    let obj = {
      loanId: loanId,
      amount: withdrawalValues.amount,
      transactionDate: withdrawalValues.transactionDate,
      accountHolderName: withdrawalValues.accountHolderName,
      bankName: withdrawalValues.bankName,
      IfscCode: withdrawalValues.IfscCode,
      // withdrawalTo: withdrawalValues.withdrawalTo||,
      accountNumber: withdrawalValues.accountNumber,
      remark: withdrawalValues.remark,
    };
    if (withdrawalValues.withdrawalTo != "Other") {
      obj.withdrawalTo = withdrawalValues.withdrawalTo;
    } else {
      obj.withdrawalTo = withdrawalValues.withdrawalTo2;
    }
    if (withdrawalValues.withdrawalTo == "Finance Company") {
      obj.finance_company = withdrawalValues.finance_company;
    }
    let api = "addPaymentWithdrawals";
    if (withdrawalValues._id != "" && withdrawalValues._id) {
      api = "updatePaymentWithdrawal";
      obj._id = withdrawalValues._id;
    }
    console.log(withdrawalValues);
    axios
      .post(`${BASE_URL}admin/${api}`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          getPaymentWithDrawals();
          setWithdrawalValues(initialWithDrawalState);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
          toast.success(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const WindowScrollTOTOp = (item) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setWithdrawalValues({ ...item });
    setWithdrawalValues({
      ...item,
      transactionDate: moment(item.transactionDate).format("YYYY-MM-DD"),
    });
  };

  if (loanId) {
    getPaymentWithDrawals();
  }
  return (
    <>
       <fieldset className="mt-4">
                          <legend>
                            <span>Customer Hold Payment</span>
                          </legend>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">RTO Hold Payment</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Previous Loan Amount"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="rtoHoldPayment"
                                  value={values.rtoHoldPayment}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">NOC Hold Payment</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter NOC Hold Payment"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="nocHoldPayment"
                                  value={values.nocHoldPayment}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Insurance Hold Payment</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Insurance Hold Payment"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="insuranceHoldPayment"
                                  value={values.insuranceHoldPayment}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
      <fieldset>
        <legend>
          <span>Withdrawal Process</span>
        </legend>
        <div className="row">
          {/* <h5 className="form-header">Withdraw process</h5> */}

          <div className="col-sm-6">
            <div class="form-group">
              <label htmlFor="">
                {" "}
                Withdrawal To <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                onChange={(e) => handleWithdrawInputChange(e)}
                name="withdrawalTo"
                value={withdrawalValues.withdrawalTo}
              >
                <option selected="true" value={"Applicant"}>
                  Applicant
                </option>
                <option value={"Co-Applicant"}>Co-Applicant</option>
                <option value={"Finance Company"}>Finance Company</option>
                <option value={"Other"}>Other</option>
              </select>
            </div>
          </div>
          {withdrawalValues.withdrawalTo == "Finance Company" && (
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="">Finance Company</label>
                <select
                  className="form-control"
                  onChange={(e) => handleWithdrawInputChange(e)}
                  name="finance_company"
                  value={withdrawalValues.finance_company}
                >
                  <option selected="true" value={""}>
                    --select--
                  </option>
                  {allCompanies
                    .filter((company) => company.company_type == "Finance")
                    ?.map((item) => (
                      <option value={item._id}>{item.company_name}</option>
                    ))}
                </select>
              </div>
            </div>
          )}

          {withdrawalValues.withdrawalTo == "Other" && (
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="">Withdraw to?</label>
                <input
                  className="form-control"
                  placeholder="Enter withdraw to whom"
                  type="text"
                  onChange={(e) => handleWithdrawInputChange(e)}
                  name="withdrawalTo2"
                  value={withdrawalValues.withdrawalTo2}
                />
              </div>
            </div>
          )}

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Account Holder Name</label>
              <input
                className="form-control"
                placeholder="Enter Account Holder Name"
                type="text"
                onChange={(e) => handleWithdrawInputChange(e)}
                name="accountHolderName"
                value={withdrawalValues.accountHolderName}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Bank Name</label>
              <input
                className="form-control"
                placeholder="Enter Bank Name"
                type="text"
                onChange={(e) => handleWithdrawInputChange(e)}
                name="bankName"
                value={withdrawalValues.bankName}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Account Number</label>
              <input
                className="form-control"
                placeholder="Enter Account Number"
                type="text"
                onChange={(e) => handleWithdrawInputChange(e)}
                name="accountNumber"
                value={withdrawalValues.accountNumber}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">IFSC Code</label>
              <input
                className="form-control"
                placeholder="Enter IFSC Code"
                type="text"
                onChange={(e) => handleWithdrawInputChange(e)}
                name="IfscCode"
                value={withdrawalValues.IfscCode}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Amount</label>
              <input
                className="form-control"
                placeholder="Enter Amount"
                type="number"
                onChange={(e) => handleWithdrawInputChange(e)}
                name="amount"
                value={withdrawalValues.amount}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Transaction Date</label>
              <input
                className="form-control"
                placeholder="Enter "
                type="date"
                onChange={(e) => handleWithdrawInputChange(e)}
                name="transactionDate"
                value={withdrawalValues.transactionDate}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Remark</label>
              <textarea
                className="form-control"
                placeholder="Enter Remark"
                onChange={(e) => handleWithdrawInputChange(e)}
                name="remark"
                value={withdrawalValues.remark}
              />
            </div>
          </div>

          <div className="form-buttons-w text-right">
            <span className=" text-right">
              <button
                className="btn btn-primary text-white"
                onClick={() => setActiveTab("thirdStep")}
              >
                Back
              </button>
            </span>
            &nbsp;&nbsp;{" "}
            <span className=" text-right">
              <button
                className="btn btn-primary text-white"
                onClick={() => addPaymentWithDrawals()}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <span>Save </span>
                )}
              </button>
            </span>
            &nbsp;&nbsp;
          </div>
        </div>
      </fieldset>

      <div className="row mt-5">
        <fieldset>
          <legend>
            <span>Withdrawal History</span>
          </legend>
          <div className="table-responsive">
            <table className="table table-lightborder">
              <thead>
                <tr>
                  <th>Withdrawal To</th>

                  <th>Account Holder Name</th>
                  <th>Bank Name</th>
                  <th>Account Number</th>
                  <th>IFSC Code</th>
                  <th>Amount</th>
                  <th>Remark</th>
                  <th>Transaction Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paymentWithdrawals?.length > 0 &&
                  paymentWithdrawals.map((item, index) => (
                    <tr key={item._id}>
                      <td>
                        {item.withdrawalTo}
                        <br />
                        {item.withdrawalTo == "Finance Company" &&
                          `Name: ${item.Companies[0]?.company_name}`}
                      </td>
                      <td>{item.accountHolderName}</td>
                      <td>{item.bankName}</td>
                      <td>{item.accountNumber}</td>
                      <td>{item.IfscCode}</td>
                      <td>{item.amount}</td>
                      <td>{item.remark}</td>
                      <td>
                        {moment(item.transactionDate).format("YYYY-MM-DD")}
                      </td>

                      <td>
                        <button className="border-0 bg-white">
                          <i
                            className="os-icon os-icon-pencil-2"
                            onClick={() => WindowScrollTOTOp(item)}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </fieldset>
      </div>
    </>
  );
}

export default PaymentWithdrawal;
