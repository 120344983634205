import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import moment from "moment";


function CompanyListing() {
  const { state } = useLocation();
  console.log("state:",state);
  const [currentPage, setCurrentPage] = useState(1);

  const [allCompanies, setAllCompanies]= useState([]);

  const navigate= useNavigate();
  const [pages, setPages]=useState(1)
const BASE_URL= process.env.REACT_APP_BASE_URL;
const getData = () => {
    const token= localStorage.getItem("token");
    const obj = {
      page: currentPage,
      limit: 8,
    };
    axios
      .post(`${BASE_URL}admin/getAllCompanies`, obj, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",

        },
      })
      .then((response) => {
        if (response.data.success) {
          setAllCompanies(response.data.data);
          setPages(response.data.remainingCount);
          console.log(response.data.data);
        }else{
          if(response.data.error_code==461){
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  const DeleteCompany = (id) => {
    const token= localStorage.getItem('token');
    if (!window.confirm("Do you really want to delete this item?")) {
      return;
    }
    axios
      .delete(`${BASE_URL}admin/deleteCompany/${id}`, {
        headers: {
          'x-access-token':`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.message);
          getData();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(()=>{
    getData();
  
  },[currentPage])
 
  return (
    <Layout>
    <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <h5 className="form-header">Company Listing</h5>

              <div className="table-responsive">
                <table className="table table-lightborder">
                  <thead>
                    <tr>
                      
                      <th>Company Type</th>
                      <th>Comapny Name</th>
                      <th>Comapny Coordinator Name</th>
                      <th>Company Contact Person Name</th>
                      <th>Status</th>
                    

                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCompanies?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.company_type}</td>
                        <td>{item.company_name}</td>

                        <td>
                          <div>
                            <p className="m-1">{item.company_coordinator_name}</p>
                            <p>{item.company_coordinator_mobile_no}</p>
                          </div></td>


                          <td>
                          <div>
                            <p className="m-1">{item.company_contact_person_name}</p>
                            <p>{item.company_contact_person_mobile_no}</p>
                          </div></td>
                        <td>{item.status}</td>

                        <td>
                       
                          <button
                            className="border-0 bg-white"
                            onClick={() =>
                                navigate("/add-company", { state: { item } })
                              }
                          >
                            <i className="os-icon os-icon-pencil-2" />
                          </button>

                          <button
                            className="text-danger border-0 bg-white ps-2"
                            onClick={() => DeleteCompany(item._id)}
                          >
                            <i className="os-icon os-icon-database-remove" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              
              </div>
            </div>
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      className="page-link"
                      
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CompanyListing;
