import React, { useEffect, useState } from "react";
import Mobilemenu from "../components/Mobilemenu";
import Desktopmenu from "../components/Desktopmenu";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dashboardimg from "../img/dashboard-img.jpg"
import MenuTop from "../components/MenuTop";
import Layout from "../components/Layout";

const Dashboard = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  return (
 <Layout>
  <div className="element-wrapper">
          <div className="element-box">
          <div className="content-i">
            <div className="content-box p-0">
              <div className="row">
                <div className="col-sm-12">
                  <div className="element-wrapper pb-0">
                   
                    <h5 className="element-heade m-0 mb-2 ps-4"> Dashboard</h5>
                    <div className="element-content" >
                      <img src={dashboardimg} className="img-fluid" style={{ width:"100%", height:"100%" }}/>
                      {/* <div className="row">
                        <div className="col-sm-4">
                          <div className="element-box el-tablo">
                            <div className="label">Users</div>
                            <div className="value">30</div>
                            <div className="trending trending-up">
                            
                              <i className="os-icon os-icon-arrow-up2" />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="element-box el-tablo">
                            <div className="label">Pending Orders</div>
                            <div className="value">40</div>
                            <div className="trending trending-down-basic">
                           
                              <i className="os-icon os-icon-arrow-2-down" />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="element-box el-tablo">
                            <div className="label">Success Order</div>
                            <div className="value">40</div>
                            <div className="trending trending-down-basic">
                          
                              <i className="os-icon os-icon-graph-down" />
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            {/* <Neworders/> */}
            
          
          
            </div>
        
   
          </div>
          </div>
        </div>


 
 </Layout>
        
       
  );
};

export default Dashboard;
