import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";



function RunningLoanListing() {
  const [loans , setloans]=useState([]);
  const [selectedStatus, setSelectedStatus]=useState("Running Leed");
  const [filteredLoans,setFilteredLoans]=useState([]);
  const navigate= useNavigate();
  const [page, setPage]=useState(1)
const BASE_URL= process.env.REACT_APP_BASE_URL;
  const getData = () => {
    const token= localStorage.getItem('token');
    const obj = {
      page: page,
      limit: 8,
    };
    axios
      .post(`${BASE_URL}admin/getAllLoan`, obj, {
        headers: {
          'x-access-token':`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setloans(response.data.data);
          setFilteredLoans(response.data.data);
          console.log("loans",response.data.data);
        }else{
          if(response.data.error_code==461){
            navigate("/login")
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const deleteEmployee=(id)=>{
    const token= localStorage.getItem('token');
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    axios.delete(`${BASE_URL}admin/deleteLoan/${id}`,{
      headers:{
        "x-access-token":`${token}`,
        "Content-Type":"Application/json",
        version:"1.0.0"
      }
    }).then((response)=>{
      if(response.data.success){
        toast.error(response.data.message);
        setloans([])
        getData();
      }
    }).catch((err)=>{
      console.log(err.message);
    })
  }
  useEffect(()=>{
    getData();
  },[])

   useEffect(() => {
    if (selectedStatus !== "") {
      setFilteredLoans(loans.filter((item) => item.status === selectedStatus));
    } else {
      setFilteredLoans(loans);
    }
  }, [selectedStatus, loans]);
  return (
    <Layout>
    <div className="content-i">
      <div className="content-box">
        <div className="element-wrapper">
          <div className="element-box">
            <div className="row d-flex justify-content-between">
              <div className="col">
              <h5 className="form-header text-bold">Loan Listing</h5>
              </div>
              <div className="col-3 text-end">
              <select className="form-control" name="selectedStatus" value={selectedStatus} onChange={(e)=>setSelectedStatus(e.target.value)}>
              <option value="">Select Loan Status</option>
              <option value="Pending Leed">Pending Leed</option>
              <option value="Rejected Leed">Rejected Leed</option>
              <option value="Running Leed">Running Leed</option>
              <option value="Agree Leed">Agree Leed</option>
             </select>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-lightborder">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Mobile No./Email</th>
                    <th>State</th>
                    <th>Class/Sagment</th>
                    <th>Model</th>
                    <th>Dist. RTO</th>
                    <th>Loan Request Amount</th>
                    <th>Loan Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredLoans.length>0?filteredLoans.map((item,index)=>(
                      <tr key={index}>
                   
                      <td>{item.name}</td>
                      <td>
                      <div>
                        <p>{item.email}</p>
                        <p>{item.mobile_no}</p>
                      </div>
                      </td>
                      <td>{item.state}</td>
                      <td>{item.vehicle_type}</td>
                      <td>{item.model}</td>
                      <td>{item.distt_rto}</td>

                      <td>{item.loan_request}</td>
                      <td>{item.status}</td>
                      
                      <td>
                    
                        <button className="border-0 bg-white">
                          <i className="os-icon os-icon-pencil-2" 
                          onClick={() =>
                            navigate("/add-loan", { state: { item } })
                          }
                          />
                        </button>
  
                        <button  className="text-danger border-0 bg-white ps-2" onClick={()=>deleteEmployee(item._id)} >
                          <i className="os-icon os-icon-database-remove" />
                        </button>
                      </td>
                    </tr>
                    )):
                    <div>
                    </div>
                  }
               
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
}

export default RunningLoanListing;
