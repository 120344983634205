import axios from "axios";
import React, { useEffect, useState } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";

function UserList() {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const getData = () => {
    const token = localStorage.getItem("token");
    const obj = {
      page: page,
      limit: 8,
    };
    axios
      .post(`${BASE_URL}admin/getUserList`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setList(response.data.data);
          console.log("user List", response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  const changeStatus=(id)=>{
    const token = localStorage.getItem("token");

    axios.get(`${BASE_URL}admin/changesUserStatus/${id}`,{
      headers:{
        "Content-Type":"Application/json",
        "version":"1.0.0",
        "x-access-token":`${token}`
      }
    }).then((res)=>{
      if(res.data.success){
        toast.success(res.data.message);
        getData();
      }else{
        toast.error(res.data.message);
      }
    }).catch((err)=>{
      console.log(err.message);
    })

  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <h5 className="form-header">User Listing</h5>
              <div className="table-responsive">
                <table className="table table-lightborder">
                  <thead>
                    <tr>
                      <th>Profile Image</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile No</th>
                      <th>Address</th>
                      <th>PinCode</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.length > 0 ? (
                      list.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <img
                              src={item.profile_image}
                              alt={item.name}
                              width={60}
                              height={60}
                            />
                          
                          </td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>

                          <td>{item.mobile_no}</td>
                          <td>{item.address}</td>
                          <td>{item.pin_code}</td>

                        <td>{item.status}</td>
                          <td>
                            <button className="border-0 rounded-1 text-white" style={{ background:"rgb(109 107 236)",lineHeight: "1.2",
    padding: "7px"}}>
                              Change<br/>
                              Status 
                              <i
                                className="os-icon os-icon-pencil-2"
                                onClick={() =>
                                    changeStatus(item._id)
                                }
                              />
                            </button>

                            {/* <button
                              className="text-danger border-0 bg-white ps-2"
                              onClick={() => deleteProduct(item._id)}
                            >
                              <i className="os-icon os-icon-database-remove" />
                            </button> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default UserList;
