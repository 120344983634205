import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
const initialState = {
  company_type: "Finance",
  company_name: "",
  company_coordinator_name: "",
  company_coordinator_mobile_no:"",
  company_contact_person_name: "",
  company_contact_person_mobile_no: "",
  company_official_email: "",
  branch_name: "",
  company_location: "",
  status: "Active",
  _id:""
};
const AddCompany = () => {
  const [loading , setLoading]=useState(false);
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const [companyId , setCompanyId]=useState("");
  const { state } = useLocation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    }
  

  const saveUpdateCompany = () => {

    const token= localStorage.getItem('token');
    if (!values.company_name) {
      toast.error("Please enter company  name!");
      return;
    }

    let api = "addCompany";
    if (values._id!="") {
      api = "updateCompany";
    }
    
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/${api}`, values, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setValues(initialState);
          // setCompanyId(response.data.data._id);
          toast.success(response.data.message);
          navigate("/company-listing")
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (state) {
      setValues({ ...state.item });
      // setCompanyId(state.item._id);
    }
  }, []);

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <h6 className="element-header"> Company</h6>
                <div className="element-box">
                  <div>
                    <h5 className="form-header">Add Company</h5>
                    <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor>Select Company Type:</label>
                        <select
                          className="form-control"
                          onChange={(e) => handleInputChange(e)}
                          name="company_type"
                          value={values.company_type}
                        >
                          <option disabled selected="true" >--select--</option>
                          <option value="Finance">Finance</option>
                          <option selected="true" value="Insurance">Insurance</option>
                         
                        </select>
                      </div>
                    </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Company Name:</label>
                          <input
                            className="form-control"
                            placeholder="Enter Company Name"
                            name="company_name"
                            onChange={(e) => handleInputChange(e)}
                            value={values.company_name}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Company Coordinator Name</label>
                          <input
                            className="form-control"
                            placeholder="Enter Company Coordinator Name"
                            name="company_coordinator_name"
                            onChange={(e) => handleInputChange(e)}
                            value={values.company_coordinator_name}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Comapny Coordinator Mobile No</label>
                          <input
                            className="form-control"
                            placeholder="Enter Company Coordinator Mobile No"
                            name="company_coordinator_mobile_no"
                            onChange={(e) => handleInputChange(e)}
                            value={values.company_coordinator_mobile_no}
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Company Contact Person Name</label>
                          <input
                            className="form-control"
                            placeholder="Enter Company Contact Person Name "
                            type="text"
                            name="company_contact_person_name"
                            onChange={(e) => handleInputChange(e)}
                            value={values.company_contact_person_name}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor>Company Contact Person Mobile No</label>
                        <input name="company_contact_person_mobile_no"
                          className="form-control"
                          placeholder="Enter Company Contact Person Mobile No"
                          type="text"
                         
                          onChange={(e) => handleInputChange(e)}
                          value={values.company_contact_person_mobile_no}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor>Company Official Email</label>
                        <input name="company_official_email"
                          className="form-control"
                          placeholder="Enter Company Official Email"
                          type="email"
                          onChange={(e) => handleInputChange(e)}
                          value={values.company_official_email}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor>Branch Name</label>
                        <input name="branch_name"
                          className="form-control"
                          placeholder="Enter Branch Name"
                          type="text"
                          onChange={(e) => handleInputChange(e)}
                          value={values.branch_name}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor>Company Location</label>
                        <input name="company_location"
                          className="form-control"
                          placeholder="Enter Comapny Location"
                          type="text"
                          rows={4}
                          onChange={(e) => handleInputChange(e)}
                          value={values.company_location}
                        />
                      </div>
                    </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Status</label>
                          <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e)}
                            name="status"
                            value={values.status}
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                     
                    </div>

                    <div className="form-buttons-w">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => saveUpdateCompany()}
                      >
                        {
                          loading? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            "Submit"
                          )
                        }
                        
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* /////////////// category listing  start */}

   
    </Layout>
  );
};

export default AddCompany;
