import React, { useEffect, useRef, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import Layout from "../../components/Layout";
import Modal from "react-bootstrap/Modal";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import moment from "moment";
import { Button } from "react-bootstrap";

const initialState = {
  name: "",
  email: "",
  mobile_no: "1234567890",
  basic_remark: "",
  alternative_mobile_no: "",
  pincode: "",
  state: "",
  district: "",
  district1: "",
  tehsil: "",
  tehsil1: "",
  address1: "",
  address2: "",

  vehicle_type: "",
  vehicle_no: "",
  model: "",
  distt_rto: "",

  occupation: "",

  insuranceCompnay:"",
  policyType:"",

  vehicle_owner: "",
  vehicle_manufacturing_date: "",
  maker_classification:"",

  policyNo:"",
  policyIssueDate:"",
  policyExpDate:"",

description:"",
  IDV:0,
  policyAmount:0,
  netAmount:0,
  remark:"",
  GSTPayableAmount:0,
  CGST:0,
  SGST:0,
  UGST:0,
  IGST:0,
  CESS:0,

  // premium details 
  totalSectionPremium:"",
  totalGrossPremium:"",
  GST:"",

};

function AddInsurance() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [states, setStates] = useState([]);
  const [paymentWithdrawals, setPaymentWithdrawals] = useState([]);
  const [districtTehsils, setDistrictTehsils] = useState([]);
  const [contactPeople, setContactPeople] = useState([]);

  const [documents, setDocuments] = useState([]);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedLoanCompanies, setSelectedLoanCompanies] = useState([]);
  const [contactPerson, setContactPerson] = useState([]);

  // const [selectedInsuranceCompany, setSelectedInsuranceCompany] = useState([]);
  const [file1, setFile1] = useState("");
  const [image, setImage] = useState(state?.item?.image);
  const fileInputRef = useRef(null);
  const [allCompanies, setAllCompanies] = useState("");
  const [values, setValues] = useState(initialState);

  const [activeTab, setActiveTab] = useState("firstStep");
  const [insuranceTaken, setInsuranceTaken] = useState("No");

  const [insuranceId, setInsuranceId] = useState(state?.item?._id);

  // contact person info
  const [name, setName] = useState("");
  const [mobile_no, setMobileNo] = useState();
  const [companyId, setCompanyId] = useState("");

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const formatResult = (item) => {
    return (
      <span style={{ display: "block", textAlign: "left" }}>
        <p className="m-0">name: {item.name}</p>
        <p className="m-0">Mobile No.: {item.mobile_no}</p>
      </span>
    );
  };
  const handleOnSelect = (item) => {
    console.log(item);
    setName(item.name);
    setMobileNo(item.mobile_no);
  };

  const handleOnSearch = (string, results) => {
    console.log(string, results);
    setName(string);
    setMobileNo("");
  };

  const handleTabClick = (tabName) => {
    if (tabName === "secondStep") {
      // if (!values.name) {
      //   toast.error("Name is required");
      return true;
      // }

      // if (!values.mobile_no) {
      //   toast.error("Mobile Number is required");
      //   return false;
      // } else {

      // }
    } else if (tabName === "thirdStep") {
      return true;
    } else if (tabName === "fourthStep") {
      return true;
    } else if (tabName === "fifthStep") {
      return true;
    } else if (tabName === "final") {
      return true;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  // add update product
  const SaveInsurance = (step, LoanCompanyApprovedId, status) => {
    if (step === "final") {
      if (
        !(
          values.payment ==
          values.sanction - values.totalDeduction - values.totalRtoExpence
        )
      ) {
        toast.error(
          "Recieved Payment amount should be equal to Sanction amount minus total deduction and total RTO expence"
        );
        return;
      }
    }
    let obj = {};

    if (step && !handleTabClick(step)) {
      return;
    }

    const token = localStorage.getItem("token");
    let api = "addInsurance";
    if (insuranceId) {
      api = "updateInsurance";
    }
    if (values.name) {
      obj.name = values.name;
    }
    if (values.email) {
      obj.email = values.email;
    }
    if (values.basic_remark) {
      obj.basic_remark = values.basic_remark;
    }
    if (values.mobile_no) {
      obj.mobile_no = values.mobile_no;
    }
    if (values.alternative_mobile_no) {
      obj.alternative_mobile_no = values.alternative_mobile_no;
    }
    if (values.pincode) {
      obj.pincode = values.pincode;
    }
    if (values.state) {
      obj.state = values.state;
    }
    if (values.district) {
      obj.district = values.district;
    }
    if (values.district != "Other" && values.district) {
      obj.district = values.district;
    } else {
      obj.district = values.district1;
    }

    if (values.tehsil == "Other") {
      obj.tehsil = values.tehsil1;
    } else {
      obj.tehsil = values.tehsil;
    }
    obj.vehicle_owner = values.vehicle_owner;
    if (values.vehicle_manufacturing_date) {
      obj.vehicle_manufacturing_date = values.vehicle_manufacturing_date;
    }

    obj.occupation = values.occupation;
    if (values.insuranceAmount) {
      obj.insuranceAmount = values.insuranceAmount;
    }

    if (values.insuranceCompany) {
      obj.insuranceCompany = values.insuranceCompany;
    }

    obj.address1 = values.address1;
    obj.address2 = values.address2;

    obj.vehicle_no = values.vehicle_no;
    if (values.vehicle_type) {
      obj.vehicle_type = values.vehicle_type;
    }
    obj.model = values.model;
    obj.distt_rto = values.distt_rto;

    if (insuranceId) {
      obj.id = insuranceId;
    }
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/${api}`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          if (step === "final") {
            navigate("/insurance-listing");
          }

          InsuranceByInsuranceId(response.data.data._id);
          setInsuranceId(response.data.data._id);
          toast.success(response.data.message);
          if (step == "secondStep") {
            AddTehsils();
          }

          if (step !== "fourthStep") {
            setActiveTab(step);
          }
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });

    // }
  };

  const InsuranceByInsuranceId = (loanId, permission, step) => {
    const token = localStorage.getItem("token");
    let obj = {
      insuranceId: insuranceId,
    };

    setLoading(true);
    axios
      .post(`${BASE_URL}admin/getInsuranceByInsuranceId`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          getDistrictTehsil();
          setLoading(false);
          setValues({ ...response.data.data[0] });
          setImage(response.data.data[0].image);
          setContactPerson(response.data.data[0].ContactPerson);

          setInsuranceId(response.data.data[0]._id);
          if (permission) {
            SaveInsurance("fourthStep");
          }
        } else {
          setLoading(false);
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const handleRadioChange = (event) => {
    setInsuranceTaken(event.target.value);
    console.log(event.target.value);
  };

  const getCompanies = () => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}admin/getAllCompanies`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setAllCompanies(response.data.data);
          console.log("setAllCompanies", response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [remark, setRemark] = useState("");
  const [statusName, setStatusName] = useState("");
  const [loanCompanyStatus, setLoanCompanyStatus] = useState("Pending Leed");
  const [currentDate, setCurrentDate] = useState("");
  const handleShow = (item) => {
    // setCompanyItem(item);
    console.log("item", item);
    setShow(true);
  };
  const handleShow1 = (item) => {
    // setCompanyItem(item);
    console.log("item", item);
    setShow1(true);
    getContactPeople(item._id);
  };

  // contact person

  const SaveContactPerson = () => {
    const token = localStorage.getItem("token");

    let obj = {
      name: name,
      mobile_no: mobile_no,
      // companyId: companyItem._id,
    };
    setLoading(true);

    axios
      .post(`${BASE_URL}admin/addContactPerson`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          // toast.success(response.data.message);
          SaveLoanCompanyContactPerson(response.data.data._id);
          setLoading(false);
          setShow1(false);
        } else {
          setLoading(false);
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const SaveLoanCompanyContactPerson = (ID) => {
    const token = localStorage.getItem("token");

    let obj = {
      insuranceId: insuranceId,
      contactPersonId: ID,
      // companyId: companyItem._id,
    };
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/addLoanCompanyContactPerson`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          InsuranceByInsuranceId(insuranceId);
          setLoading(false);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  // state
  const getStates = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getALLState`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setStates(response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const getDistrictTehsil = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllDistrictTehsil`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setDistrictTehsils(response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const AddTehsils = () => {
    const token = localStorage.getItem("token");

    setLoading(true);
    axios
      .post(
        `${BASE_URL}admin/updateTehsils`,
        { tehsil: values.tehsil1, district: values.district },
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const getContactPeople = (id) => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}admin/getContactPeopleByCompanyId`,
        { id: id },
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setContactPeople(response.data.data);
          console.log("setContactPeople", response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // documents
  const [docFile, setDocFile] = useState("");
  const [docFile1, setDocFile1] = useState("");
  const docfileInputRef = useRef(null);
  const [docFileName, setDocFIleName] = useState("");

  const [docName, setDocName] = useState("");
  const [docId, setDocId] = useState("");
  const addDocFile = (e) => {
    setDocFile(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(e.target.files[0]);
      setDocFile1((e) => [newImageSrc]);
    }
  };

  const getFileNameFromUrl = (url) => {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname.split("/").pop();
  };

  const [fileNames, setFileNames] = useState([]);

  const handleDocChange = (e) => {
    setDocFile(e.target.files);
    const files = Array.from(e.target.files);
    setFileNames(files.map((file) => file.name));
  };
  const addLoanDocs = () => {
    if (!docName) {
      toast.error("please select doc name");
    }
    const token = localStorage.getItem("token");
    const fd = new FormData();
    fd.append("doc_file", docFile[0]);
    fd.append("doc_name", docName);
    fd.append("applicantType", values.applicantType);

    fd.append("insuranceId", insuranceId);
    let api = "addLoanDoc";
    if (docId != "") {
      fd.append("id", docId);
      api = "updateLoanDocs";
    }
    axios
      .post(`${BASE_URL}admin/${api}`, fd, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart-form-data",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          getLoanDocs();
          // setDocFile("");
          setDocName("");
          setDocId("");
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getLoanDocs = () => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}admin/getAllLoanDocs`,
        { insuranceId: insuranceId },
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          // toast.success(response.data.message);
          setDocuments(response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteDoc = (id) => {
    const token = localStorage.getItem("token");
    if (!window.confirm("Do you really want to delete this item?")) {
      return;
    }
    axios
      .delete(`${BASE_URL}admin/deleteLoanDoc/${id}`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.message);
          getLoanDocs();
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const updateLoanDoc = (item) => {
    setDocId(item._id);
    setDocName(item.doc_name);
    setDocFile(item.doc_file);
    setDocFile1(item.doc_file);
    setFileNames(item.doc_file);
  };

  const [allDocName, setAllDocName] = useState([]);

  const getDocName = () => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `${BASE_URL}admin/getAllDocs`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setAllDocName(response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (insuranceId && values.finance_company_name) {
      setInsuranceTaken("Yes");
    }

    setValues({ ...state?.item });
    setSelectedCompanies(state?.item?.Companies);
    setSelectedLoanCompanies(state?.item.Companies);
    setContactPerson(state?.item?.ContactPerson);
    setInsuranceId(state?.item._id);

    getStates();
    getDistrictTehsil();
    if (insuranceId) {
      getLoanDocs();
      getDocName();
    }
    getCompanies();
  }, []);


  useEffect(() => {
    const GSTPayableAmount =
      (parseInt(values?.CGST) || 0) +
      (parseInt(values?.SGST) || 0) +
      (parseInt(values?.UGST) || 0) +
      (parseInt(values?.IGST) || 0) +
      (parseInt(values?.CESS) || 0) 
    setValues((prevValues) => ({
      ...prevValues,
      GSTPayableAmount,
    }));
    console.log(GSTPayableAmount);
  }, [
    values?.CGST,
    values?.SGST,
    values?.UGST,
    values?.IGST,
    values?.CESS,
  ]);

  return (
    <>
      <Layout>
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box">
                <div>
                  <div className="steps-w w-100">
                    <div className="step-triggers">
                      <a
                        href="javascript:void(0)"
                        className={`step-trigger ${
                          activeTab == "firstStep" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("firstStep")}
                      >
                        Applicant Details
                      </a>
                      <a
                        href="javascript:void(0)"
                        className={`step-trigger ${
                          activeTab == "secondStep" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("secondStep")}
                      >
                        Vehical details
                      </a>
                      <a
                        href="javascript:void(0)"
                        className={`step-trigger ${
                          activeTab == "thirdStep" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("thirdStep")}
                      >
                        Policy Details
                      </a>
                      <a
                        href="javascript:void(0)"
                        className={`step-trigger ${
                          activeTab == "fourthStep" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("fourthStep")}
                      >
                        Premuim & GST Details
                      </a>

                      {/* <a
                        href="javascript:void(0)"
                        className={`step-trigger ${
                          activeTab == "fifthStep" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("fifthStep")}
                      >
                        Payment Withdrawal
                      </a> */}
                    </div>
                    <div className="step-contents">
                      <div
                        className={`step-content  ${
                          activeTab == "firstStep" ? "active" : ""
                        }`}
                        id="stepContent1"
                        style={{
                          display: activeTab == "firstStep" ? "block " : "none",
                        }}
                      >
                        <fieldset>
                          <legend>
                            <span>Basic Information</span>
                          </legend>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor>Applicant Name</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Name"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="name"
                                  value={values.name}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor>Email:</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Email"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="email"
                                  value={values.email}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor>Mobile Number:</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Mobile No."
                                  type="Number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="mobile_no"
                                  value={values.mobile_no}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor>Business / Occupation:</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Business/Occupation"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="occupation"
                                  value={values.occupation}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor>
                                  Alternative Mobile Number:
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Alternative Mobile No."
                                  type="Number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="alternative_mobile_no"
                                  value={values.alternative_mobile_no}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor>Address1</label>
                                <input
                                  name="address1"
                                  placeholder="Enter Street address"
                                  className="form-control"
                                  onChange={(e) => handleInputChange(e)}
                                  value={values.address1}
                                ></input>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor>Address2</label>
                                <input
                                  name="address2"
                                  placeholder="Enter permanent address"
                                  className="form-control"
                                  onChange={(e) => handleInputChange(e)}
                                  value={values.address2}
                                ></input>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label> state</label>
                                <select
                                  className="form-control "
                                  onChange={(e) => handleInputChange(e)}
                                  name="state"
                                  value={values.state}
                                >
                                  <option value="">--select--</option>
                                  {states.map((state) => (
                                    <option value={state.name} key={state.key}>
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            {values.state == "Madhya Pradesh" ? (
                              <>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label> District</label>
                                    {/* <button className="d-none" onClick={()=>console.log(values)}>click</button> */}
                                    <select
                                      className="form-control "
                                      onChange={(e) => handleInputChange(e)}
                                      name="district"
                                      value={values?.district}
                                    >
                                      <option value="">--select--</option>

                                      {districtTehsils.map((item) => (
                                        <option
                                          key={item.district}
                                          value={item.district}
                                        >
                                          {item.district}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                {values.district?.length > 0 &&
                                  values.tehsil != "Other" && (
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label> Tehsils</label>
                                        {/* <button className="d-none" onClick={()=>console.log(values)}>click</button> */}
                                        <select
                                          className="form-control "
                                          onChange={(e) => handleInputChange(e)}
                                          name="tehsil"
                                          value={values?.tehsil}
                                        >
                                          <option value="">--select--</option>

                                          {districtTehsils
                                            .find(
                                              (districtObj) =>
                                                districtObj.district ===
                                                values.district
                                            )
                                            ?.tehsils?.map((tehsil) => (
                                              <option
                                                key={tehsil}
                                                value={tehsil}
                                              >
                                                {tehsil}
                                              </option>
                                            ))}
                                          <option value="Other">Other</option>
                                        </select>
                                      </div>
                                    </div>
                                  )}

                                {values.tehsil == "Other" && (
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <label for="">Tehsils</label>
                                      <input
                                        className="form-control text-capitalize"
                                        placeholder="Enter Tehsil Name"
                                        type="text"
                                        onChange={(e) => handleInputChange(e)}
                                        name="tehsil1"
                                        value={values.tehsil1}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label for="">District</label>
                                    <input
                                      className="form-control"
                                      placeholder="Enter District Name"
                                      type="text"
                                      onChange={(e) => handleInputChange(e)}
                                      name="district"
                                      value={values.district}
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label for="">Tehsils</label>
                                    <input
                                      className="form-control text-capitalize"
                                      placeholder="Enter Tehsil Name"
                                      type="text"
                                      onChange={(e) => handleInputChange(e)}
                                      name="tehsil"
                                      value={values.tehsil}
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Pincode </label>
                                <input
                                  class="form-control"
                                  placeholder="Enter Pincode"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="pincode"
                                  value={values.pincode}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Remark </label>
                                <textarea
                                  class="form-control"
                                  placeholder="Enter remark"
                                  // type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="basic_remark"
                                  value={values.basic_remark}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        <div className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => SaveInsurance("secondStep")}
                            // onClick={}
                          >
                            {loading ? (
                              <span className="spinner-border spinner-border-sm"></span>
                            ) : (
                              <span>Save & Continue</span>
                            )}
                          </button>
                        </div>
                        {insuranceId && (
                          <fieldset>
                            <legend>
                              <span>Add Documents</span>
                            </legend>
                            <div className="row">
                              <div className="col-sm-6">
                                <div class="form-group">
                                  <label htmlFor=""> Applicant Type</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => handleInputChange(e)}
                                    name="applicantType"
                                    value={values.applicantType}
                                  >
                                    <option selected="true" value={"Applicant"}>
                                      Applicant
                                    </option>
                                    <option value={"Co-Applicant"}>
                                      Co-Applicant
                                    </option>
                                  </select>
                                </div>
                              </div>

                              {values.applicantType == "Co-Applicant" && (
                                <>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">
                                        Co-Applicant Name{" "}
                                      </label>
                                      <input
                                        class="form-control"
                                        placeholder="Enter Pincode"
                                        type="text"
                                        onChange={(e) => handleInputChange(e)}
                                        name="coApplicantName"
                                        value={values.coApplicantName}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">
                                        Co-Applicant Mobile No.{" "}
                                      </label>
                                      <input
                                        class="form-control"
                                        placeholder="Enter Pincode"
                                        type="text"
                                        onChange={(e) => handleInputChange(e)}
                                        name="coApplicantMobileNo"
                                        value={values.coApplicantMobileNo}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}

                              <div className="col-sm-6">
                                <div class="form-group">
                                  <label htmlFor=""> Doc Name</label>
                                  <select
                                    className="form-control text-capitalize"
                                    onChange={(e) => setDocName(e.target.value)}
                                    name="docName"
                                    value={docName}
                                  >
                                    <option selected="true" value={""}>
                                      -select-
                                    </option>
                                    {allDocName?.length &&
                                      allDocName.map((item, index) => (
                                        <option key={index} value={item.name}>
                                          {item.name}
                                        </option>
                                      ))}
                                    {/* <option
                                      selected="true"
                                      value={"Aadhar Card"}
                                    >
                                      Aadhar Card
                                    </option>
                                    <option
                                      selected="true"
                                      value={"Aadhar Card"}
                                    >
                                      Profile Picture
                                    </option>
                                    <option value={"PAN Card"}>PAN Card</option>
                                    <option value={"Driving Licence"}>
                                      Driving Licence
                                    </option> */}
                                  </select>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-group">
                                  {/* <label htmlFor="">Doc File</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Doc Name"
                                  type="file"
                                  ref={docfileInputRef}
                                  onChange={(e) => addDocFile(e)}
                                  name="docFile"
                                  // value={docFile}
                                />
                              </div>
                              <iframe
                                src={docFile1}
                                width="200px"
                                height="150px"
                                style={{ borderRadius: "20px" }}
                              ></iframe> */}

                                  <div className="container">
                                    <label
                                      htmlFor="et_pb_contact_brand_file_request_0 "
                                      className="et_pb_contact_form_label pb-0"
                                      // onDragOver={handleDragOver}
                                      // onDragLeave={handleDragLeave}
                                      // onDrop={handleDrop}
                                    >
                                      Upload File
                                    </label>
                                    <input
                                      type="file"
                                      // id="fileUpload"
                                      // id="et_pb_contact_brand_file_request_0"
                                      className="file-upload"
                                      onChange={handleDocChange}
                                    />

                                    <div className="file_names">
                                      {fileNames ? fileNames : fileNames[0]}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-buttons-w text-right">
                              <span className=" text-right">
                                {/* <button
                              className="btn btn-primary text-white"
                              onClick={() => setActiveTab("secondStep")}
                            >
                              Back
                            </button> */}
                              </span>
                              &nbsp;&nbsp;{" "}
                              <span className=" text-right">
                                <button
                                  className="btn btn-primary text-white"
                                  onClick={() => addLoanDocs()}
                                >
                                  Save
                                </button>
                              </span>
                              &nbsp;&nbsp;
                            </div>

                            <div className="table-responsive">
                              <table className="table table-lightborder">
                                <thead>
                                  <tr>
                                    <th>Doc Name</th>
                                    <th>Doc File</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {documents?.length > 0 &&
                                    documents.map((item, index) => (
                                      <tr key={item._id}>
                                        <td>{item.doc_name}</td>
                                        <td>
                                          <a
                                            href={item.doc_file}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Download
                                          </a>
                                        </td>

                                        <td>
                                          <button className="border-0 bg-white">
                                            <i
                                              className="os-icon os-icon-pencil-2"
                                              onClick={() =>
                                                updateLoanDoc(item)
                                              }
                                            />
                                          </button>

                                          <button
                                            className="text-danger border-0 bg-white ps-2"
                                            onClick={() => deleteDoc(item._id)}
                                          >
                                            <i className="os-icon os-icon-database-remove" />
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </fieldset>
                        )}
                      </div>
                      <div
                        className={`step-content ${
                          activeTab == "secondStep" ? "active" : ""
                        }`}
                        style={{
                          display:
                            activeTab == "secondStep" ? "block " : "none",
                        }}
                      >
                        <fieldset>
                          <legend>
                            <span>Vehicle Information</span>
                          </legend>
                          <div className="row">
                         
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label> Segment/Class</label>
                                <select
                                  className="form-control"
                                  onChange={(e) => handleInputChange(e)}
                                  name="vehicle_type"
                                  value={values.vehicle_type}
                                >
                                  <option value="">--select--</option>
                                  <option value="TRACTOR">{"TRACTOR"}</option>
                                  <option value="CAR">{"CAR"}</option>

                                  <option value="TRUCK">{"TRUCK"}</option>
                                  <option value="DUMPER">{"DUMPER"}</option>
                                  <option value="lOADING VEHICAL">
                                    {"lOADING VEHICAL"}
                                  </option>
                                  <option value="HARVESTER">
                                    {"HARVESTER"}
                                  </option>
                                  <option value="JCB">{"JCB"}</option>
                                  <option value="PIKUP">{"PIKUP"}</option>
                                </select>
                              </div>
                            </div>
                     
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Vehicle No/ RC Card</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Vehicle No."
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="vehicle_no"
                                  value={values.vehicle_no}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Model</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Model"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="model"
                                  value={values.model}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Maker Classification</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Maker Classification"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="maker_classification"
                                  value={values.maker_classification}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Distt RTO</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Distt RTO"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="distt_rto"
                                  value={values.distt_rto}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Vehicle Owner Name</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Vehicle Owner Name"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="vehicle_owner"
                                  value={values.vehicle_owner}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">
                                  Vehicle-Manufacture Date
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Registration Date"
                                  type="date"
                                  onChange={(e) => handleInputChange(e)}
                                  name="vehicle_manufacturing_date"
                                  value={moment(
                                    values.vehicle_manufacturing_date
                                  ).format("YYYY-MM-DD")}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        <div className="form-buttons-w text-right">
                          <span className=" text-right">
                            <button
                              className="btn btn-primary text-white"
                              onClick={() => setActiveTab("firstStep")}
                            >
                              Back
                            </button>
                          </span>
                          &nbsp;&nbsp;{" "}
                          <span className=" text-right">
                            <button
                              className="btn btn-primary text-white"
                              onClick={() => SaveInsurance("thirdStep")}
                            >
                              {loading ? (
                                <span className="spinner-border spinner-border-sm"></span>
                              ) : (
                                <span>Save & Continue</span>
                              )}
                            </button>
                          </span>
                          &nbsp;&nbsp;
                        </div>
                      </div>
                      <div
                        className={`step-content ${
                          activeTab == "thirdStep" ? "active" : ""
                        }`}
                        id="stepContent3"
                        style={{
                          display: activeTab == "thirdStep" ? "block" : "none",
                        }}
                      >
                        <fieldset>
                          <legend>
                            <span>Policy Details</span>
                          </legend>
                          <div className="row">
                          <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Company</label>
                                <select
                                  className="form-control"
                                  onChange={(e) => handleInputChange(e)}
                                  name="insuranceCompany"
                                  value={values.insuranceCompnay}
                                >
                                    <option value={""}>
                                          --select insurance company--
                                    </option>
                                  {allCompanies &&
                                    allCompanies?.filter(
                                      (company) =>
                                        company.company_type == "Insurance"
                                    ).length > 0 &&
                                    allCompanies
                                      ?.filter(
                                        (company) =>
                                          company.company_type == "Insurance"
                                      )
                                      ?.map((company) => (
                                        <option value={company?._id}>
                                          {company.company_name}
                                        </option>
                                      ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Policy Type</label>
                                <select
                                  className="form-control"
                                  onChange={(e) => handleInputChange(e)}
                                  name="policyType"
                                  value={values.policyType}
                                >
                                 <option value={""}>
                                    --select policy--
                                  </option>
                                  <option value={"First Party"}>
                                    First Party
                                  </option>
                                  <option value={"Third Party"}>
                                    Thrid Party
                                  </option>
                                </select>
                              </div>
                            </div>


                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Policy No</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter policy No."
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="policyNo"
                                  value={values.policyNo}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">IDV (Insured Declared Value)</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter IDV Amount"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="IDV"
                                  value={values.IDV}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Policy Amount</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter policy Amount"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="policyAmount"
                                  value={values.policyAmount}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Description</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter policy Amount"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="description"
                                  value={values.description}
                                />
                              </div>
                            </div>


                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Policy Issue Date</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter policy issue date"
                                  type="date"
                                  onChange={(e) => handleInputChange(e)}
                                  name="policyIssueDate"
                                  value={values.policyIssueDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Policy Expire Date</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter policy Expire date"
                                  type="date"
                                  onChange={(e) => handleInputChange(e)}
                                  name="policyExpDate"
                                  value={values.policyExpDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label>
                                have you taken insurance previously on this
                                vehicle?
                              </label>
                              <div className="row">
                                <div className="col-2">
                                  <label htmlFor="insurancePreviouslyYes">
                                    Yes
                                  </label>
                                  &nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    id="insurancePreviouslyYes"
                                    name="loanPreviously"
                                    value={"Yes"}
                                    checked={insuranceTaken === "Yes"}
                                    onChange={handleRadioChange}
                                  />
                                </div>

                                <div className="col-2">
                                  <label htmlFor="insurancePreviouslyNo">
                                    No
                                  </label>
                                  &nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    id="insurancePreviouslyNo"
                                    name="loanPreviously"
                                    value={"No"}
                                    checked={insuranceTaken === "No"}
                                    onChange={handleRadioChange}
                                  />
                                </div>
                              </div>
                            </div>
                            {insuranceTaken == "Yes" && (
                              <fieldset>
                                <legend>
                                  <span>Previous Insurance Details</span>
                                </legend>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">
                                        Insurance Company Name
                                      </label>
                                      <input
                                        className="form-control"
                                        placeholder="Enter Insurance Company Name"
                                        type="text"
                                        onChange={(e) => handleInputChange(e)}
                                        name="insurance_company_name"
                                        value={values.insurance_company_name}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            )}

                            <div className="form-buttons-w text-right">
                              <span className=" text-right">
                                <button
                                  className="btn btn-primary text-white"
                                  onClick={() => setActiveTab("secondStep")}
                                >
                                  Back
                                </button>
                              </span>
                              &nbsp;&nbsp;{" "}
                              <span className=" text-right">
                                <button
                                  className="btn btn-primary text-white"
                                  onClick={() => SaveInsurance("fourthStep")}
                                >
                                  {loading ? (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  ) : (
                                    <span>Save</span>
                                  )}
                                </button>
                              </span>
                              &nbsp;&nbsp;
                            </div>
                          </div>
                        </fieldset>
                     
                      </div>

                      <div
                        className={`step-content ${
                          activeTab == "fourthStep" ? "active" : ""
                        }`}
                        id="stepContent3"
                        style={{
                          display: activeTab == "fourthStep" ? "block" : "none",
                        }}
                      >
                        <div className="row">
                        </div>
                        <fieldset>
                          <legend>
                            <span>Premium Details</span>
                          </legend>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Total Section Premium</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter total section premium"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="totalSectionPremium"
                                  value={values?.totalSectionPremium}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Total Gross Premium</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter total section premium"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="totalGrossPremium"
                                  value={values?.totalGrossPremium}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">GST</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter SGST"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="SGST"
                                  value={values?.GST}
                                />
                              </div>
                            </div>


                        
                          </div>
                        </fieldset>
                        <fieldset>
                          <legend>
                            <span>GST Details</span>
                          </legend>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">CGST</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter CGST"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="CGST"
                                  value={values?.CGST}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">SGST</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter SGST"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="SGST"
                                  value={values?.SGST}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">UGST</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter UGST"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="UGST"
                                  value={values?.UGST}
                                />
                              </div>
                            </div>

                            
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">IGST</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter IGST"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="IGST"
                                  value={values?.IGST}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">CESS</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter CESS"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="CESS"
                                  value={values?.CESS}
                                />
                              </div>
                            
                            </div>

                            <div className="col-12 ">
                            <div
                              className="d-flex justify-content-between"
                              style={{
                                backgroundColor: "#e4e4e4",
                                padding: "10px",
                              }}
                            >
                              <div>
                                <b>Total GST Charges</b>
                              </div>
                              {values ? (
                                <div>
                                  <b>
                                    <i className="fa fa-inr"></i>{" "}
                                    {values.GSTPayableAmount}
                                  </b>
                                </div>
                              ) : (
                                <div>0</div>
                              )}
                            </div>
                          </div>

                        
                          </div>
                        </fieldset>

                   

                   

                        <div className="form-buttons-w text-right">
                          <span className=" text-right">
                            <button
                              className="btn btn-primary text-white"
                              onClick={() => setActiveTab("thirdStep")}
                            >
                              Back
                            </button>
                          </span>
                          &nbsp;&nbsp;{" "}
                          <span className=" text-right">
                            <button
                              className="btn btn-primary text-white"
                              onClick={() => SaveInsurance("final")}
                            >
                              {loading ? (
                                <span className="spinner-border spinner-border-sm"></span>
                              ) : (
                                <span>Save </span>
                              )}
                            </button>
                          </span>
                          &nbsp;&nbsp;
                        </div>
                      </div>
{/* 
                      <div
                        className={`step-content ${
                          activeTab == "fifthStep" ? "active" : ""
                        }`}
                        id="stepContent3"
                        style={{
                          display: activeTab == "fifthStep" ? "block" : "none",
                        }}
                      >
                        <fieldset>
                          <legend>
                            <span>Withdrawal Process</span>
                          </legend>
                          <div className="row">
                            <div className="form-buttons-w text-right">
                              <span className=" text-right">
                                <button
                                  className="btn btn-primary text-white"
                                  onClick={() => setActiveTab("thirdStep")}
                                >
                                  Back
                                </button>
                              </span>
                              &nbsp;&nbsp; &nbsp;&nbsp;
                            </div>
                          </div>
                        </fieldset>

                        <div className="row mt-5"></div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>

      <Modal show={show1} onHide={() => setShow1(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Contact Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label> Name</label>
          {/* <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="form-control"
          /> */}
          <ReactSearchAutocomplete
            items={contactPeople}
            onSearch={handleOnSearch}
            // onHover={handleOnHover}
            onSelect={handleOnSelect}
            placeholder="Search By Name"
            // onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
          />
          <br />
          <label> Mobile Number</label>
          <input
            type="text"
            name="mobile_no"
            value={mobile_no}
            onChange={(e) => setMobileNo(e.target.value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow1(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => SaveContactPerson()}>
            {loading ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              <span>Save</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddInsurance;
