import axios from "axios";
import {GetJSONHederWithToken} from "../../Utilities/CommonMethods"

const BASE_URL = process.env.REACT_APP_BASE_URL
export const getState= async()=>{
    try {
        let header = GetJSONHederWithToken();
        const data = await axios.get(`${BASE_URL}admin/getALLState`,header);
        return data

    } catch (error) {
        console.log(error)
    }
}

export const getDistrict= async()=>{
    try {
        let header = GetJSONHederWithToken();
        const data = await axios.get(`${BASE_URL}admin/getAllDistrictTehsil`,header);
        return data

    } catch (error) {
        console.log(error)
    }
}

export const createTehsil= async(obj)=>{
    try {
        let header = GetJSONHederWithToken();
        const data = await axios.post(`${BASE_URL}admin/updateTehsils`,obj,header);
        return data

    } catch (error) {
        console.log(error)
    }
}