import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { getState, getDistrict } from "../../services/State/State";
function BasicDetails({
    values,
    handleInputChange,
    loading,
    setActiveTab,
}) {
    const [states, setStates] = useState([]);
    const [districtTehsils, setDistrictTehsils] = useState([]);
    const getStates = async () => {
        const data = await getState();
        if (data.data.success) {
          setStates(data.data.data);
        } else {
          setStates([]);
        }
      };
      const getDistrictTehsils = async () => {
        const data = await getDistrict();
        if (data.data.success) {
          setDistrictTehsils(data.data.data);
        } else {
          setDistrictTehsils([]);
        }
      };

      useEffect(()=>{
        getStates();
        getDistrictTehsils();
      },[])
  return (
  <>
    <fieldset>
                        <legend>
                          <span>Basic Information</span>
                        </legend>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Full Name</label>
                              <input
                                className="form-control"
                                placeholder="Enter Name"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                name="name"
                                value={values.name}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Email:</label>
                              <input
                                className="form-control"
                                placeholder="Enter Email"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                name="email"
                                value={values.email}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Mobile Number:</label>
                              <input
                                className="form-control"
                                placeholder="Enter Mobile No."
                                type="Number"
                                onChange={(e) => handleInputChange(e)}
                                name="mobile_no"
                                value={values.mobile_no}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Alternative Mobile Number:</label>
                              <input
                                className="form-control"
                                placeholder="Enter Alternative Mobile No."
                                type="Number"
                                onChange={(e) => handleInputChange(e)}
                                name="alternative_mobile_no"
                                value={values.alternative_mobile_no}
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for=""> Date of Birth</label>
                              <input
                                class="form-control"
                                placeholder=""
                                type="date"
                                onChange={(e) => handleInputChange(e)}
                                name="dob"
                                value={moment(values.dob).format("YYYY-MM-DD")}
                              />
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="">Pincode </label>
                              <input
                                class="form-control"
                                placeholder="Enter Pincode"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                name="pincode"
                                value={values.pincode}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label> state</label>
                              <select
                                className="form-control "
                                onChange={(e) => handleInputChange(e)}
                                name="state"
                                value={values.state}
                              >
                                <option value="">--select--</option>
                                {states.map((state) => (
                                  <option value={state.name} key={state.key}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          {values.state == "Madhya Pradesh" ? (
                            <>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label> District</label>
                                  <select
                                    className="form-control "
                                    onChange={(e) => handleInputChange(e)}
                                    name="district"
                                    value={values?.district}
                                  >
                                    <option value="">--select--</option>

                                    {districtTehsils.map((item) => (
                                      <option
                                        key={item.district}
                                        value={item.district}
                                      >
                                        {item.district}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              {values.district?.length > 0 &&
                                values.tehsil != "Other" && (
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <label> Tehsils</label>

                                      <select
                                        className="form-control "
                                        onChange={(e) => handleInputChange(e)}
                                        name="tehsil"
                                        value={values?.tehsil}
                                      >
                                        <option value="">--select--</option>

                                        {districtTehsils
                                          .find(
                                            (districtObj) =>
                                              districtObj.district ===
                                              values.district
                                          )
                                          ?.tehsils?.map((tehsil) => (
                                            <option key={tehsil} value={tehsil}>
                                              {tehsil}
                                            </option>
                                          ))}
                                        <option value="Other">Other</option>
                                      </select>
                                    </div>
                                  </div>
                                )}

                              {values.tehsil == "Other" && (
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label for="">Tehsils</label>
                                    <input
                                      className="form-control text-capitalize"
                                      placeholder="Enter Tehsil Name"
                                      type="text"
                                      onChange={(e) => handleInputChange(e)}
                                      name="tehsil1"
                                      value={values.tehsil1}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label for="">District</label>
                                  <input
                                    className="form-control"
                                    placeholder="Enter District Name"
                                    type="text"
                                    onChange={(e) => handleInputChange(e)}
                                    name="district"
                                    value={values.district}
                                  />
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label for="">Tehsils</label>
                                  <input
                                    className="form-control text-capitalize"
                                    placeholder="Enter Tehsil Name"
                                    type="text"
                                    onChange={(e) => handleInputChange(e)}
                                    name="tehsil"
                                    value={values.tehsil}
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Address</label>
                              <input
                                name="address"
                                rows={4}
                                placeholder="Enter Permanent Address "
                                className="form-control"
                                onChange={(e) => handleInputChange(e)}
                                value={values.address}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </fieldset>
  </>
  )
}

export default BasicDetails