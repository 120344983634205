import React from "react";
import Mobilemenu from "./Mobilemenu";
import Desktopmenu from "./Desktopmenu";
import { Breadcrumb } from "react-bootstrap";
import MenuTop from "./MenuTop";

function Layout({ children }) {
  return (
    <div className="all-wrapper menu-top">
      <div className="layout-w">
        <MenuTop />

        <div className="row">
          <div className="col-sm-12">
            <div className="element-wrapper">
              {/* <h6 className="element-header">Dashboard Box</h6> */}
              <br />
              {children}
            </div>
          </div>
        </div>
      </div>
      <div className="display-type" />
    </div>
  );
}

export default Layout;
