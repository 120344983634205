import React, { useEffect, useRef, useState } from "react";
import { getState, getDistrict } from "../../services/State/State";
import {
  getDocName,
  getLoanDocs,
  deleteDoc,
} from "../../services/Loan/document";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function BasicDetails({
  handleInputChange,
  values,
  SaveLoan,
  loanId,
  loading,
}) {
  const [docFile, setDocFile] = useState("");
  const [docFile1, setDocFile1] = useState("");
  const [allDocName, setAllDocName] = useState([]);
  const docfileInputRef = useRef(null);
  const [docFileName, setDocFIleName] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);

  const [docName, setDocName] = useState("");
  const [docId, setDocId] = useState("");
  const [states, setStates] = useState([]);
  const [districtTehsils, setDistrictTehsils] = useState([]);

  const getStates = async () => {
    const data = await getState();
    if (data.data.success) {
      setStates(data.data.data);
    } else {
      setStates([]);
    }
  };
  const getDistrictTehsils = async () => {
    const data = await getDistrict();
    if (data.data.success) {
      setDistrictTehsils(data.data.data);
    } else {
      setDistrictTehsils([]);
    }
  };

  // docs
  const addDocFile = (e) => {
    setDocFile(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(e.target.files[0]);
      setDocFile1((e) => [newImageSrc]);
    }
  };

  const getFileNameFromUrl = (url) => {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname.split("/").pop();
  };

  const [fileNames, setFileNames] = useState([]);

  const handleDocChange = (e) => {
    setDocFile(e.target.files);
    const files = Array.from(e.target.files);
    setFileNames(files.map((file) => file.name));
  };

  const addLoanDocs = () => {
    if (!docName) {
      toast.error("please select doc name");
    }
    const token = localStorage.getItem("token");
    const fd = new FormData();
    fd.append("doc_file", docFile[0]);
    fd.append("doc_name", docName);
    fd.append("applicantType", values.applicantType);
    fd.append("loanId", loanId);
    let api = "addLoanDoc";
    if (docId != "") {
      fd.append("id", docId);
      api = "updateLoanDocs";
    }
    axios
      .post(`${BASE_URL}admin/${api}`, fd, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart-form-data",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          getLoanDoc();
          setDocName("");
          setDocId("");
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteDocuments = async (id) => {
    let data = await deleteDoc(id);
    if (data.data.success) {
      toast.success(data.data.message);
      getLoanDoc();
    }
  };

  const getAllDocName = async () => {
    const data = await getDocName();
    if (data.data.success) {
      setAllDocName(data.data.data);
    }
  };

  const getLoanDoc = async () => {
    const data = await getLoanDocs(loanId);
    if (data.data.success) {
      setDocuments(data.data.data);
    }else{
      setDocuments([]);
    }
  };

  const updateLoanDoc = (item) => {
    setDocId(item._id);
    setDocName(item.doc_name);
    setDocFile(item.doc_file);
    setDocFile1(item.doc_file);
    setFileNames(item.doc_file);
  };

  useEffect(() => {
    getStates();
    getDistrictTehsils();
    getAllDocName();
    getLoanDoc();
  }, []);
  useEffect(() => {
    getLoanDoc();
  }, [loanId]);

  return (
    <>
      <fieldset>
        <legend>
          <span>Basic Information</span>
        </legend>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor>Applicant Name</label>
              <input
                className="form-control"
                placeholder="Enter Name"
                type="text"
                onChange={(e) => handleInputChange(e)}
                name="name"
                value={values.name}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor>Email:</label>
              <input
                className="form-control"
                placeholder="Enter Email"
                type="text"
                onChange={(e) => handleInputChange(e)}
                name="email"
                value={values.email}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor>Mobile Number:</label>
              <input
                className="form-control"
                placeholder="Enter Mobile No."
                type="Number"
                onChange={(e) => handleInputChange(e)}
                name="mobile_no"
                value={values.mobile_no}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor>Business / Occupation:</label>
              <input
                className="form-control"
                placeholder="Enter Business/Occupation"
                type="text"
                onChange={(e) => handleInputChange(e)}
                name="occupation"
                value={values.occupation}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor>Alternative Mobile Number:</label>
              <input
                className="form-control"
                placeholder="Enter Alternative Mobile No."
                type="Number"
                onChange={(e) => handleInputChange(e)}
                name="alternative_mobile_no"
                value={values.alternative_mobile_no}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor>Address1</label>
              <input
                name="address1"
                placeholder="Enter Street address"
                className="form-control"
                onChange={(e) => handleInputChange(e)}
                value={values.address1}
              ></input>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor>Address2</label>
              <input
                name="address2"
                placeholder="Enter permanent address"
                className="form-control"
                onChange={(e) => handleInputChange(e)}
                value={values.address2}
              ></input>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label> state</label>
              <select
                className="form-control "
                onChange={(e) => handleInputChange(e)}
                name="state"
                value={values.state}
              >
                <option value="">--select--</option>
                {states.map((state) => (
                  <option value={state.name} key={state.key}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {values.state == "Madhya Pradesh" ? (
            <>
              <div className="col-sm-6">
                <div className="form-group">
                  <label> District</label>

                  <select
                    className="form-control "
                    onChange={(e) => handleInputChange(e)}
                    name="district"
                    value={values?.district}
                  >
                    <option value="">--select--</option>

                    {districtTehsils.map((item) => (
                      <option key={item.district} value={item.district}>
                        {item.district}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {values.district?.length > 0 && values.tehsil != "Other" && (
                <div className="col-sm-6">
                  <div className="form-group">
                    <label> Tehsils</label>

                    <select
                      className="form-control "
                      onChange={(e) => handleInputChange(e)}
                      name="tehsil"
                      value={values?.tehsil}
                    >
                      <option value="">--select--</option>

                      {districtTehsils
                        .find(
                          (districtObj) =>
                            districtObj.district === values.district
                        )
                        ?.tehsils?.map((tehsil) => (
                          <option key={tehsil} value={tehsil}>
                            {tehsil}
                          </option>
                        ))}
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
              )}

              {values.tehsil == "Other" && (
                <div className="col-sm-6">
                  <div className="form-group">
                    <label for="">Tehsils</label>
                    <input
                      className="form-control text-capitalize"
                      placeholder="Enter Tehsil Name"
                      type="text"
                      onChange={(e) => handleInputChange(e)}
                      name="tehsil1"
                      value={values.tehsil1}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="col-sm-6">
                <div className="form-group">
                  <label for="">District</label>
                  <input
                    className="form-control"
                    placeholder="Enter District Name"
                    type="text"
                    onChange={(e) => handleInputChange(e)}
                    name="district"
                    value={values.district}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label for="">Tehsils</label>
                  <input
                    className="form-control text-capitalize"
                    placeholder="Enter Tehsil Name"
                    type="text"
                    onChange={(e) => handleInputChange(e)}
                    name="tehsil"
                    value={values.tehsil}
                  />
                </div>
              </div>
            </>
          )}

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Pincode </label>
              <input
                class="form-control"
                placeholder="Enter Pincode"
                type="text"
                onChange={(e) => handleInputChange(e)}
                name="pincode"
                value={values.pincode}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Remark </label>
              <textarea
                class="form-control"
                placeholder="Enter remark"
                // type="text"
                onChange={(e) => handleInputChange(e)}
                name="basic_remark"
                value={values.basic_remark}
              />
            </div>
          </div>
        </div>
      </fieldset>

      <div className=" text-right">
        <button
          className="btn btn-primary text-white"
          onClick={() => SaveLoan("secondStep")}
          // onClick={}
        >
          {loading ? (
            <span className="spinner-border spinner-border-sm"></span>
          ) : (
            <span>Save & Continue</span>
          )}
        </button>
      </div>

      {/* loan documents  start */}

      {loanId && (
        <fieldset>
          <legend>
            <span>Add Documents</span>
          </legend>
          <div className="row">
            <div className="col-sm-6">
              <div class="form-group">
                <label htmlFor=""> Applicant Type</label>
                <select
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="applicantType"
                  value={values.applicantType}
                >
                  <option selected="true" value={"Applicant"}>
                    Applicant
                  </option>
                  <option value={"Co-Applicant"}>Co-Applicant</option>
                </select>
              </div>
            </div>

            {values.applicantType == "Co-Applicant" && (
              <>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="">Co-Applicant Name </label>
                    <input
                      class="form-control"
                      placeholder="Enter Pincode"
                      type="text"
                      onChange={(e) => handleInputChange(e)}
                      name="coApplicantName"
                      value={values.coApplicantName}
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="">Co-Applicant Mobile No. </label>
                    <input
                      class="form-control"
                      placeholder="Enter Pincode"
                      type="text"
                      onChange={(e) => handleInputChange(e)}
                      name="coApplicantMobileNo"
                      value={values.coApplicantMobileNo}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="col-sm-6">
              <div class="form-group">
                <label htmlFor=""> Doc Name</label>
                <select
                  className="form-control text-capitalize"
                  onChange={(e) => setDocName(e.target.value)}
                  name="docName"
                  value={docName}
                >
                  <option selected="true" value={""}>
                    -select-
                  </option>
                  {allDocName?.length &&
                    allDocName.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <div className="container">
                  <label
                    htmlFor="et_pb_contact_brand_file_request_0 "
                    className="et_pb_contact_form_label pb-0"
                    // onDragOver={handleDragOver}
                    // onDragLeave={handleDragLeave}
                    // onDrop={handleDrop}
                  >
                    Upload File
                  </label>
                  <input
                    type="file"
                    // id="fileUpload"
                    // id="et_pb_contact_brand_file_request_0"
                    className="file-upload"
                    onChange={handleDocChange}
                  />

                  <div className="file_names">
                    {fileNames ? fileNames : fileNames[0]}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-buttons-w text-right">
            <span className=" text-right">
              {/* <button
                              className="btn btn-primary text-white"
                              onClick={() => setActiveTab("secondStep")}
                            >
                              Back
                            </button> */}
            </span>
            &nbsp;&nbsp;{" "}
            <span className=" text-right">
              <button
                className="btn btn-primary text-white"
                onClick={() => addLoanDocs()}
              >
                Save
              </button>
            </span>
            &nbsp;&nbsp;
          </div>

          <div className="table-responsive">
            <table className="table table-lightborder">
              <thead>
                <tr>
                  <th>Doc Name</th>
                  <th>Doc File</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {documents?.length > 0 &&
                  documents.map((item, index) => (
                    <tr key={item._id}>
                      <td>{item.doc_name}</td>
                      <td>
                        <a
                          href={item.doc_file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download
                        </a>
                      </td>

                      <td>
                        <button className="border-0 bg-white">
                          <i
                            className="os-icon os-icon-pencil-2"
                            onClick={() => updateLoanDoc(item)}
                          />
                        </button>

                        <button
                          className="text-danger border-0 bg-white ps-2"
                          onClick={() => deleteDocuments(item._id)}
                        >
                          <i className="os-icon os-icon-database-remove" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </fieldset>
      )}
    </>
  );
}

export default BasicDetails;
