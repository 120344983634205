import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./login.css";
import finaceImage  from "../../img/finance-image.jpg"
import finacebg  from "../../img/finance-bg.jpg"


function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const submit = (e) => {
    const obj = {
      email: email,
      role: "2",
      password: password,
    };

    if (email.length > 100) {
      toast.error("please enter valid email address length ");
    } else {
      if (isValidEmail(email)) {
        setEmail(email);
      } else {
        toast.error("please enter valid email address");
      }
    }

    if (password.length > 6) {
      toast.error("password must be upto 6 digit ");
    } else if (password.length <= 3) {
      toast.error("password must be of minimum 4 digit");
    }
    axios
      .post(`${BASE_URL}admin/adminLogin`, obj, {
        headers: {
          version: "1.0.0",
          "Content-type": "Application/json",
        },
      })
      .then(function (response) {
        if (response.data.success) {
          localStorage.setItem("User", response.data.data);
          toast.success(response.data.message);
          localStorage.setItem("token", response.data.data.token);
          navigate("/");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="all-wrapper menu-side with-pattern"  >
      <div className="auth-box-w">
      <div className="row">
        <div className="col-md-6 col-12 pt-3">
        <h4 className="auth-header">Login Form</h4>
        <div className="loginForm" >
          <div className="form-group">
            <label htmlFor>Email</label>
            <input
              className="form-control"
              placeholder="Enter your email"
              type="text"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            {/* <div className="pre-icon os-icon os-icon-user-male-circle"></div> */}
          </div>
          <div className="form-group">
            <label htmlFor>Password</label>
            <input
              className="form-control"
              placeholder="Enter your password"
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            {/* <div className="pre-icon os-icon os-icon-fingerprint"></div> */}
          </div>
          <div className="buttons-w">
            <button className="btn btn-primary" onClick={() => submit()}>
              Log me in
            </button>
            <div className="form-check-inline">
              {/* <label className="form-check-label">
            <input className="form-check-input" type="checkbox" />Remember Me</label> */}
            </div>
          </div>
        </div>
        </div>
        <div className="col-md-6 col-12 p-0" style={{ backgroundImage:`linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(${finaceImage})`,  backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
       
         
        
        
          </div>
      </div>
     
      
      </div>
    </div>
  );
}

export default Login;
