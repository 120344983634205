import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import moment from "moment";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {getLoanHistory} from "../../services/Loan/loanhistory"

function ViewLoanCompanyHistory() {
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { state } = useLocation();

  const [show, setShow] = useState(false);
  const [remark, setRemark] = useState("");
  const [statusName, setStatusName] = useState("");
  const [id , setId]=useState("");
  const [currentDate , setCurrentDate]=useState("");
  const [loanCompanyStatus, setLoanCompanyStatus] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const getData = async() => {
    const token = localStorage.getItem("token");
    const obj = {
      page: page,
      limit: 8,
      companyId: state.obj.companyId,
      loanId: state.obj.loanId,
    };
    let data=await getLoanHistory(obj);
    if(data.data.success){
      setHistory(data.data.data);
    }else{
      setHistory([]);
    }

  };

  const EditHistoryData = () => {
    const token = localStorage.getItem("token");
    const obj = {
      remark:remark,
      statusName:statusName,
      currentDate:currentDate,
      status:loanCompanyStatus,
      id:id
    };
    axios
      .post(`${BASE_URL}admin/updateLoanCompaniesHistory`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          setShow(false);
          getData();
          if(loanCompanyStatus=="Approved"){
            navigate("/add-loan",{state:{LoanStatus:"Approved",loanId:state.obj.loanId,approvedHistoryId:id}});
          }
          console.log("History", response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const ShowModel = (item) => {
    setStatusName(item.statusName);
    setLoanCompanyStatus(item.status);
    setStatusName(item.statusName);
    setRemark(item.remark);
    setId(item._id);
    setCurrentDate(moment(item.currentDate).format("YYYY-MM-DD"));
    setShow(true);
  };
  useEffect(() => {
    getData();
    console.log("history", state);
  }, []);

  return (
    <>
      <Layout>
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box">
                <h5 className="form-header"><i className="fa fa-long-arrow-left fs-2" onClick={()=>window.history.back()}></i>  </h5>
                <h4 className="form-header"> History </h4>
                <div className="table-responsive">
                  {history.length > 0 ? (
                    <>
                      <table className="table table-lightborder">
                        <thead>
                          <tr>
                            {/* <th>Company Type</th>
                             <th>Company Name</th> */}
                            <th>Status Name</th>
                            <th>Status Description</th>
                            {/* <th>Vehicle No.</th> */}
                            {/* <th>Model</th> */}
                            <th>Status</th>
                            <th>Created At</th>

                            {/* <th>price</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {history.length > 0 ? (
                            history.map((item, index) => (
                              <tr key={index}>
                                {/* <td>{item.Companies[0].company_type}</td>
                      <td>{item.Companies[0].company_name}</td> */}
                                <td>{item.statusName}</td>
                                <td>{item.remark}</td>
                                <td>{item.status}</td>
                                <td>{moment(item.currentDate).format("YYYY-MM-DD")}</td>

                                <td>
                                  <button className="border-0 bg-white">
                                    <i
                                      className="os-icon os-icon-pencil-2"
                                      onClick={() => ShowModel(item)}
                                    />
                                  </button>

                                  {/* <button  className="text-danger border-0 bg-white ps-2" 
                         >
                          <i className="os-icon os-icon-database-remove" />
                        </button> */}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <div> </div>
                          )}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <div>
                      <div className="text-center">
                        <h3>No History Found</h3>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Select Status</label>
          <select
            name="loanCompanyStatus"
            className="form-control"
            value={loanCompanyStatus}
            onChange={(e) => setLoanCompanyStatus(e.target.value)}
          >
            <option value="Pending Leed" selected="true">
              Pending Leed
            </option>
            <option value="Running Leed">Running Leed</option>
            <option value="Reject Leed">Reject Leed</option>
            <option value="Agree Leed">Agree Leed</option>

          </select>
          <br />
          <label>Status Name</label>
          <input
            type="text"
            name="statusName"
            value={statusName}
            onChange={(e) => setStatusName(e.target.value)}
            className="form-control"
          />
          <br />
          <label>Status Description</label>
          <textarea
            type="text"
            rows={3}
            className="form-control"
            name="remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
          <br />
          <input type="date" className="form-control" value={currentDate} name="currentDate" onChange={(e)=>setCurrentDate(e.target.value)}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="primary"
               onClick={() => EditHistoryData()}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ViewLoanCompanyHistory;
