import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
const initialState = {
   name:"",
  status: "Active",
  _id:""
};
const AddDocument = () => {
  const [loading , setLoading]=useState(false);
  // const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const [allDocName , setAllDocName]=useState([])
  const { state } = useLocation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate= useNavigate();
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    }
  

  const saveUpdateDoc = () => {

    const token= localStorage.getItem('token');
    if (!values.name) {
      toast.error("Enter Doc Name!");
      return;
    }

    let api = "addDocuments";
    if (values._id!="") {
      api = "updateDoc";
    }
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/${api}`, values, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setValues(initialState);
          // setCompanyId(response.data.data._id);
          getData();
          toast.success(response.data.message);
          // navigate("/company-listing")
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };


  const getData = () => {
    const token= localStorage.getItem("token");
   
    axios
      .post(`${BASE_URL}admin/getAllDocs`, {}, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",

        },
      })
      .then((response) => {
        if (response.data.success) {
          setAllDocName(response.data.data);
         
        }else{
          if(response.data.error_code==461){
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  const DeleteDoc = (id) => {
    const token= localStorage.getItem('token');
    if (!window.confirm("Do you really want to delete this item?")) {
      return;
    }
    axios
      .delete(`${BASE_URL}admin/deleteDoc/${id}`, {
        headers: {
          'x-access-token':`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.message);
          setAllDocName([]);
          getData();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    if (state) {
      setValues({ ...state.item });
      // setCompanyId(state.item._id);
    }
    getData();
  }, []);

  const updateName=(item)=>{
   
    setValues({...values, name:item.name, _id:item._id });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }




  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <h6 className="element-header"> Document</h6>
                <div className="element-box">
                  <div>
                    <h5 className="form-header">Add Document Name</h5>
                    <div className="row">
                
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Doc Name:</label>
                          <input
                            className="form-control text-capitalize"
                            placeholder="Enter Doc Name"
                            name="name"
                            onChange={(e) => handleInputChange(e)}
                            value={values.name}
                            type="text"
                          />
                        </div>
                      </div>
                    
                     
                    </div>

                    <div className="form-buttons-w">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => saveUpdateDoc()}
                      >
                        {
                          loading? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            "Submit"
                          )
                        }
                        
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="element-wrapper">
            <div className="element-box">
              <h5 className="form-header">Document Name Listing</h5>

              <div className="table-responsive">
                <table className="table table-lightborder">
                  <thead>
                    <tr>
                      
                      {/* <th>Co</th> */}
                      <th> Name</th>
                     <th>Created At</th>
                      {/* <th>Status</th> */}
                    

                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allDocName?.map((item, index) => (
                      <tr key={index}>
                        <td className="text-capitalize">{item.name}</td>
                        <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
<td>
                          <button
                            className="border-0 bg-white"
                            onClick={() =>
                              updateName(  item )
                              }
                          >
                            <i className="os-icon os-icon-pencil-2" />
                          </button>

                          <button
                            className="text-danger border-0 bg-white ps-2"
                            onClick={() => DeleteDoc(item._id)}
                          >
                            <i className="os-icon os-icon-database-remove" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              
              </div>
            </div>
         
          </div>
        </div>
      </div>

      {/* /////////////// category listing  start */}

   
    </Layout>
  );
};

export default AddDocument;
