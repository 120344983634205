import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";


function LoanListing() {
  const [loans , setloans]=useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus]=useState("");
  const [filteredLoans,setFilteredLoans]=useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState('');
  const [results, setResults] = useState([]);
  const navigate= useNavigate();
  const [pages, setPages]=useState(1)
const BASE_URL= process.env.REACT_APP_BASE_URL;
  const getData = () => {
    const token= localStorage.getItem('token');
    const obj = {
      page: currentPage,
      limit: 8,
    };
    axios
      .post(`${BASE_URL}admin/getAllLoan`, obj, {
        headers: {
          'x-access-token':`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setloans(response.data.data);
          setResults(response.data.data);
          setPages(response.data.remainingCount);
          console.log("loans",response.data.data);
        }else{
          if(response.data.error_code==461){
            navigate("/login")
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const deleteEmployee=(id)=>{
    const token= localStorage.getItem('token');
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    axios.delete(`${BASE_URL}admin/deleteLoan/${id}`,{
      headers:{
        "x-access-token":`${token}`,
        "Content-Type":"Application/json",
        version:"1.0.0"
      }
    }).then((response)=>{
      if(response.data.success){
        toast.error(response.data.message);
        setloans([])
        getData();
      }
    }).catch((err)=>{
      console.log(err.message);
    })
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(()=>{
    getData();
  },[currentPage])

   useEffect(() => {
    if (selectedStatus !== "") {
      setFilteredLoans(loans.filter((item) => item.status === selectedStatus));
    } else {
      if(loans.length>0){
        setFilteredLoans(loans);
      }
    }
  }, [selectedStatus, loans]);

  const searchLoans = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_URL}admin/searchloan`, {
        params: {
          search: searchTerm, // Search by name, mobile number, or vehicle number
          status: status      // Optional: Filter by status
        },
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          version: "1.0.0"
        }
      });
  
      if (response.data.success) {
        setResults(response.data.data); // Handle the response
      } else {
        setResults([]);
      }
    } catch (error) {
      console.error('Error fetching loans:', error);
    }
  };
  

  useEffect(()=>{
    searchLoans()
  },[searchTerm, status])
  return (
    <Layout>
    <div className="content-i">
      <div className="content-box">
        <div className="element-wrapper">
          <div className="element-box">
          <h5 className="form-header text-bold">Loan Listing</h5>
            <div className="row d-flex justify-content-between my-4">
              <div className="col">
            
              <input
          type="text"
          placeholder="Search by name, mobile, or vehicle number"
          className="searchLoan"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
              </div>
              <div className="col-3 text-end">
              <select className="form-control searchSelect" name="status" value={status} onChange={(e)=>setStatus(e.target.value)}
                style={{ height:"45px", borderRadius:"0px" }}>
              <option value="" selected="true">Select Loan Status</option>
              <option value="Pending Leed">Pending Leed</option>
              <option value="Rejected Leed">Rejected Leed</option>
              <option value="Running Leed">Running Leed</option>
              <option value="Agree Leed">Agree Leed</option>
             </select>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-lightborder">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Mobile No./Email</th>
                    <th>State</th>
                    <th>Class/Sagment</th>
                    <th>Model</th>
                    <th>Dist. RTO</th>
                    <th>Loan Request Amount</th>
                    <th>Loan Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                   results.length>0?results.map((item,index)=>(
                      <tr key={index}>
                   
                      <td>{item.name}</td>
                      <td>
                      <div>
                        <p>{item.email}</p>
                        <p>{item.mobile_no}</p>
                      </div>
                      </td>
                      <td>{item.state}</td>
                      <td>{item.vehicle_type}</td>
                      <td>{item.model}</td>
                      <td>{item.distt_rto}</td>

                      <td>{item.loan_request}</td>
                      <td>{item.status}</td>
                      
                      <td>
                    
                        <button className="border-0 bg-white">
                          <i className="os-icon os-icon-pencil-2" 
                          onClick={() =>
                            navigate("/add-loan", { state: { item } })
                          }
                          />
                        </button>
  
                        <button  className="text-danger border-0 bg-white ps-2" onClick={()=>deleteEmployee(item._id)} >
                          <i className="os-icon os-icon-database-remove" />
                        </button>
                      </td>
                    </tr>
                    )):
                    <div>
                    </div>
                  }
               
                </tbody>
              </table>
            </div>
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      <>
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      </>
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
}

export default LoanListing;
