import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import moment from "moment";


function ExpenceListing() {
  const [currentPage, setCurrentPage] = useState(1);
  const [allExpences, setAllExpences]= useState([]);
  const [paymentMode, setPaymentMode]=useState("");
  const [filteredData , setFilteredData]=useState([])
 const navigate= useNavigate()

  const [pages, setPages]=useState(1)
const BASE_URL= process.env.REACT_APP_BASE_URL;
const getData = () => {
    const token= localStorage.getItem("token");
    const obj = {
      page: currentPage,
      limit: 8,
    };
    axios
      .post(`${BASE_URL}admin/getAllExpence`, obj, {
        headers: {
          "x-access-token":`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",

        },
      })
      .then((response) => {
        if (response.data.success) {
            setAllExpences(response.data.data);
            setFilteredData(response.data.data);
            setPages(response.data.remainingCount);

        }else{
          if(response.data.error_code==461){
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  const DeleteExpence = (id) => {
    const token= localStorage.getItem('token');
    if (!window.confirm("Do you really want to delete this item?")) {
      return;
    }
    axios
      .delete(`${BASE_URL}admin/deleteExpence/${id}`, {
        headers: {
          'x-access-token':`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.message);
          getData();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  




  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(()=>{
    getData();
  
  },[currentPage])


  useEffect(() => {
    if (paymentMode !== "") {
      setFilteredData(allExpences.filter((item) => item.paymentMode === paymentMode));
      console.log(filteredData);
    } else {
      if(allExpences.length>0){
        setFilteredData(allExpences);
      console.log(filteredData);
      }
    }
  }, [paymentMode, allExpences]);
 
  return (
    <Layout>
    <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
            <div className="row d-flex justify-content-between">
              <div className="col">
              <h5 className="form-header text-bold">Expence Listing</h5>
              </div>
              <div className="col-3 text-end">
              <select className="form-control" name="paymentMode" value={paymentMode} onChange={(e)=>setPaymentMode(e.target.value)}>
              <option value="" selected="true">Select Loan Status</option>
              <option value="Cash">Cash</option>
              <option value="Online">Online </option>
              <option value="Bank Deposit">Bank Deposit</option>
              <option value="Check">Check</option>
             </select>
              </div>
            </div>
      
              <div className="table-responsive">
                <table className="table table-lightborder">
                  <thead>
                    <tr>
                      
                      <th>Year</th>
                      <th>Head</th>
                      <th>Subhead</th>
                      <th>Amount</th>
                      <th>Transaction Date</th>
                      <th>Payment Type</th>
                      <th>Payment Mode</th>


                    

                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.year}</td>
                        <td>{item.heads[0]?.name}</td>
                        <td>{item.subheads[0]?.name}</td>
                        <td>{item.amount}</td>
                        <td>{moment(item.transactionDate).format("YY-MM-DD")}</td>
                        <td>{item.paymentType}</td>
                        <td>{item.paymentMode}</td>




                        <td>
                       
                          <button
                            className="border-0 bg-white"
                            onClick={() =>
                                navigate("/add-expence", { state: { item } })
                              }
                          >
                            <i className="os-icon os-icon-pencil-2" />
                          </button>

                          <button
                            className="text-danger border-0 bg-white ps-2"
                            onClick={() => DeleteExpence(item._id)}
                          >
                            <i className="os-icon os-icon-database-remove" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              
              </div>
            </div>
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      <>
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      </>
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ExpenceListing;
